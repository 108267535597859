import validateGpAddress from "../../validators/validateGpAddress";
import validatePatientDetails from "../../validators/validatePatientDetails";
import validatePharmacyAddress from "../../validators/validatePharmacyAddress";
import validatePatientAddress from "../../validators/validatePatientAddress";

function validateForm(values: any) {
  const patientErrors = validatePatientDetails({
    ...values.attributes,
    clientIdentifier: values.users[0].attributes.clientIdentifier,
    clientIdentifierRequired: values.clients[0].attributes.clientIdentifierRequired,
    employerRequiredOnBookings: values.employerRequiredOnBookings
  });
  const addressErrors = validatePatientAddress(values.attributes);
  const pharmacyErrors = validatePharmacyAddress(values.attributes);
  const gpErrors = validateGpAddress(values.attributes);

  if (patientErrors) {
    delete patientErrors.clientId;
  }

  const combinedErrors = {
    ...addressErrors,
    ...pharmacyErrors,
    ...gpErrors,
    ...patientErrors,
  };

  delete combinedErrors.clientIdentifier;

  const prefix = "attributes.";

  const arrayOfErrors = Object.entries(combinedErrors).map(([key, value]) => [
    `${prefix}${key}`,
    value,
  ]);

  if (patientErrors.clientIdentifier) {
    arrayOfErrors.push(["users[0].attributes.clientIdentifier", patientErrors.clientIdentifier])
  }

  const formattedCombinedErrors = Object.fromEntries(arrayOfErrors);

  return formattedCombinedErrors;
}

export default validateForm;
