import React from "react";
import {
  Edit,
  useNotify,
  useRefresh,
  TextInput,
  SimpleForm,
  SelectInput,
  SaveButton,
  Toolbar,
  useRecordContext,
} from "react-admin";
import { validateCreateGpSurgery } from "./validateCreateGpSurgery";
import { Stack } from "../layout/Stack";
import { Group } from "../layout/Group";
import { Heading } from "../ui/Heading";
import { Grid } from "@mui/material";
import { ToolbarClasses } from "ra-ui-materialui";
import { DeleteButton } from "../DeleteButton";

const FormToolbar = () => {
  const record = useRecordContext();

  return (
    <Toolbar>
      <div className={ToolbarClasses.defaultToolbar}>
        <SaveButton />
        <DeleteButton
          label={"Delete"}
          resource={"gpSurgeries"}
          confirmTitle={`Delete ${record.name}?`}
          successMessage={`${record.name} surgery was successfully deleted.`}
        />
      </div>
    </Toolbar>
  );
};

export function GpSurgeriesEdit() {
  const notify = useNotify();
  const refresh = useRefresh();
  const inputStyles = {
    marginBottom: 0,
  };
  const countryChoices = [
    { id: "GB", name: "United Kingdom" },
    { id: "IE", name: "Ireland" },
  ];

  return (
    <Edit
      mutationMode={"pessimistic"}
      title={"Edit GP Surgery"}
      mutationOptions={{
        onSuccess: () => {
          notify("Changes to gp surgery were successful");
          refresh();
        },
      }}
    >
      <SimpleForm
        mode="onBlur"
        validate={validateCreateGpSurgery}
        noValidate
        toolbar={<FormToolbar />}
      >
        <Stack>
          <Group heading={<Heading level={2}>Details</Heading>}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  source="name"
                  label="Surgery Name"
                  required
                  fullWidth
                />
                <TextInput
                  source="email"
                  required
                  fullWidth
                  style={{ marginTop: "4px" }}
                />
              </Grid>
            </Grid>
          </Group>
          <Group heading={<Heading level={2}>Address</Heading>}>
            <Grid item xs={12} sm={6}>
              <div style={{ flexDirection: "column", display: "flex" }}>
                <TextInput
                  sx={inputStyles}
                  source="address.line[0]"
                  label="Address Line One"
                  required
                  fullWidth
                />
                <TextInput
                  sx={inputStyles}
                  source="address.line[1]"
                  label="Address Line Two"
                  fullWidth
                />
                <TextInput
                  sx={inputStyles}
                  source="address.line[2]"
                  label="Address Line Three"
                  fullWidth
                />
                <TextInput
                  sx={inputStyles}
                  source="address.city"
                  label="City"
                  required
                  fullWidth
                />
                <TextInput
                  source="address.postalCode"
                  sx={inputStyles}
                  label="Post Code"
                  required
                  fullWidth
                />
                <SelectInput
                  sx={inputStyles}
                  source="address.country"
                  label="Country"
                  choices={countryChoices}
                  optionValue="id"
                  optionText="name"
                  required
                  fullWidth
                />
              </div>
            </Grid>
          </Group>
        </Stack>
      </SimpleForm>
    </Edit>
  );
}
