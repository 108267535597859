import React from "react";
import { useWatch } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { Heading } from "../../ui/Heading";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
} from "@mui/material";
import { ClientIdentifierDTO } from "../../../types/validation";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";

const MAX_ROW_COUNT = 25;

export function ListNewIdentifiers() {
  const newIdentifiers = useWatch({
    name: "newIdentifiers",
  }) as ClientIdentifierDTO["data"]["attributes"][];

  const clinicalProductsEnabled = useWatch({ name: "attributes.clinicalProductsEnabled" });
  const clinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);

  const showClinicalProducts = clinicalProductsEnabled && (clinicalProductsEnabledFlag || clientClinicalProductsEnabledFlag);

  if (!newIdentifiers?.length) {
    return null;
  }

  const slice = newIdentifiers.slice(0, MAX_ROW_COUNT);

  return (
    <div>
      <Heading level={4}>Identifiers to be added</Heading>
      <Typography variant="body1">
        Showing {slice.length} of {newIdentifiers.length} rows
      </Typography>

      <TableContainer style={{ listStyle: "none", padding: "0 0 16px" }}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Client Identifier</TableCell>
                    <TableCell>Name</TableCell>
                    {showClinicalProducts && <TableCell>Product code</TableCell>}
                </TableRow>
            </TableHead>
          <TableBody>
            {slice.map(identifier => {
              return (
                <TableRow key={identifier.clientIdentifier}>
                  <TableCell>{identifier.clientIdentifier}</TableCell>
                  <TableCell>{identifier.name}</TableCell>
                  {showClinicalProducts ? <TableCell>{identifier.productCode}</TableCell> : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
