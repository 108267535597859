import { getAll } from "../index";
import { FeatureFlagService } from "../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../featureFlags/flags";
import Papa from "papaparse";
import { ClientEmployerDTO } from "../../types/clientEmployer";

export interface EmployersDownloadBody {
  clientId: string;
}

async function createEmployersDownload(body: EmployersDownloadBody) {
  const { clientId } = body;

  const { data } = await getAll(`/ms/employers?filter[clientId]=${clientId}&include=clinicalProducts&page[size]=100`);

  const employers: ClientEmployerDTO["data"][] = Object.values(data);

  const featureFlags = FeatureFlagService.getInstance();
  const clinicalProductsEnabled = featureFlags.getFlag(
    TOGGLE_ENABLE_CLINICAL_PRODUCTS
  );

  const clientClinicalProductsEnabled = featureFlags.getFlag(
    TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS
  );

  const formattedEmployers = clinicalProductsEnabled || clientClinicalProductsEnabled ?
    employers
      .map(el => {
        return [ el.attributes.name, el.clinicalProducts?.[0]?.attributes?.code];
      }) : employers
      .map(el => {
        return [el.attributes.name];
      });

  const csv = Papa.unparse(formattedEmployers);
  
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = "employers.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default createEmployersDownload;
