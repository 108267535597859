import React from "react";
import {
  Edit,
  Title,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useNotify,
  useRefresh,
  TextField,
  FieldProps,
  Labeled,
  useRecordContext,
  RaRecord,
} from "react-admin";
import {
  contractQualificationChoices,
} from "../../constants/constants";
import { EditActions } from "../Actions";
import validateClientForm from "./validateClientForm";
import { getClientFunctionEndpoints } from "../utils/clientFunctionHelpers";
import { PMSEditTab } from "./tabs/PMSEditTab";
import { Qualification } from "../../types/clinicalPractitioners";
import { ClientContractDTO } from "../../types/clients";
import {
  ClientFunctionValidationDTO,
  ClientFunctionValidationEdit,
} from "../../types/validation";
import { GeneralEditTab } from "./tabs/GeneralEditTab";
import { ServiceConfigEditTab } from "./tabs/ServiceConfigEditTab";
import { ScrollableTabbedForm } from "../ScrollableTabLayouts";
import { InstructionAside } from "../InstructionAside";
import { ContractEditTab } from "./tabs/ContractEditTab";
import { PaymentEditTab } from "./tabs/PaymentEditTab";
import { AppointmentFiltersDTO } from "../../types/appointments";
import dayjs from "dayjs";
import { NotificationEditTab } from "./tabs/NotificationEditTab";
import isEmpty from "lodash/isEmpty";
import { EmployersEditTab } from "./tabs/EmployersEditTab";
import { ClientClinicianPoolsEditTab } from "./tabs/ClientClinicianPoolsEditTab";
import { ValidationEditTab } from "./tabs/ValidationEditTab";
import { useFlag } from "../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../featureFlags/flags";
import { ClinicalProductsEditTab } from "./tabs/ClinicalProductsEditTab";
import { useParams } from "react-router-dom";

function PageTitle() {
  const record = useRecordContext();
  const { name } = record?.attributes ?? "";

  return <Title title={name} />;
}

export function EditToolbar(props: Omit<ToolbarProps, "width">) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}

export function IdField(props: FieldProps) {
  if (!props.record?.id) return null;

  return (
    <Labeled {...props} label={`${props.label}`}>
      <TextField source="id" />
    </Labeled>
  );
}

function transformContract(contract: ClientContractDTO["data"]) {
  const { requiredQualifications = [] } = contract.attributes;
  const qualifications: Partial<Record<Qualification, boolean>> = {};
  const qualificationIds = contractQualificationChoices.map(({ id }) => id);

  qualificationIds.forEach(qualification => {
    const isRequired = requiredQualifications.includes(qualification);
    qualifications[qualification] = isRequired;
  });

  return {
    ...contract,
    attributes: {
      ...contract.attributes,
      ...qualifications,
    },
  };
}

function transformClientFunctionValidation(
  clientFunctionValidation: ClientFunctionValidationEdit
): ClientFunctionValidationDTO["data"] {
  const { clientName, ...rest } = clientFunctionValidation;
  const restEndpoint = getClientFunctionEndpoints(clientName);
  const restEndpointRecord = rest.restEndpoints[0];

  const transformedRecord = {
    ...rest,
    restEndpoints: [
      {
        ...restEndpointRecord,
        attributes: {
          ...restEndpointRecord.attributes,
          remoteUrl: restEndpoint,
        },
      },
    ],
  };

  return transformedRecord;
}

function transformAppointmentFilters(
  appointmentFilters: AppointmentFiltersDTO["data"]
): AppointmentFiltersDTO["data"] {
  const { parsedStartTime, parsedEndTime } = appointmentFilters.attributes;

  const dailyStartTime = dayjs(parsedStartTime).format("HH:mm:ss");
  appointmentFilters.attributes.dailyStartTime = dailyStartTime;

  const dailyEndTime = dayjs(parsedEndTime).format("HH:mm:ss");
  appointmentFilters.attributes.dailyEndTime = dailyEndTime;

  return appointmentFilters;
}

function transformNotificationSettings(record: RaRecord) {
  const { notificationSettings, existingNotificationSettings } = record;
  const isEmailFromAddressEmpty =
    notificationSettings?.attributes?.emailFromAddress === "";
  const isFromNameEmpty =
    notificationSettings?.attributes?.emailFromName === "";

  if (
    isEmpty(existingNotificationSettings) &&
    isEmailFromAddressEmpty &&
    isFromNameEmpty
  ) {
    return {};
  }

  return notificationSettings;
}

interface TemplateIds {
  attributes?: {
    templates?: {
      [property: string]: {
        id: string;
        name: string;
      };
    };
    id?: string;
    type: "batchSendGridNotificationTemplates";
  };
}

function transformTemplateIds(templateIds: TemplateIds) {
  const templateIdsToArray = Object.entries(
    templateIds?.attributes?.templates ?? []
  ).filter(([key, value]) => {
    if (value?.id || (!value?.id && value?.name)) {
      return [key, value];
    }
  });

  const templateIdsToObject = Object.fromEntries(templateIdsToArray);

  return {
    ...templateIds,
    attributes: {
      ...templateIds.attributes,
      templates: templateIdsToObject,
    },
  };
}


export function ClientEdit() {
  const { id: clientId } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();
  const clinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);
  const showClinicalProducts = clinicalProductsEnabled || clientClinicalProductsEnabled as boolean;

  return (
    <Edit
      mutationOptions={{
        onSuccess: () => {
          notify("Changes to client contract were successful");
          refresh();
        },
      }}
      mutationMode="pessimistic"
      aside={<InstructionAside clientId={clientId} />}
      actions={<EditActions />}
      title={<PageTitle />}
      transform={record => {
        const transformedContract = transformContract(record.contract);
        const transformedValidationRules = transformClientFunctionValidation(
          record.clientFunctionValidation
        );
        const transformedAppointmentFilters = transformAppointmentFilters(
          record.appointmentFilters
        );

        const transformedNotificationSettings =
          transformNotificationSettings(record);

        const transformedTemplateIds = transformTemplateIds(record.templateIds);

        return {
          ...record,
          notify,
          contract: transformedContract,
          clientFunctionValidation: transformedValidationRules,
          appointmentFilters: transformedAppointmentFilters,
          notificationSettings: transformedNotificationSettings,
          templateIds: transformedTemplateIds,
        };
      }}
    >
      <ScrollableTabbedForm
        warnWhenUnsavedChanges
        validate={validateClientForm}
        toolbar={<EditToolbar />}
        noValidate
        // This is due to the E2E tests failing when run headlessly. We should look into why `onBlur` doesn't work
        mode="onBlur"
      >
        <GeneralEditTab />
        <ContractEditTab path="brandSettings" />
        <ServiceConfigEditTab path="serviceConfig" />
        <ClientClinicianPoolsEditTab path="cpManagement" />
        <PaymentEditTab path="payments" />
        <NotificationEditTab path="notifications" />
        <ValidationEditTab path="validation" />
        { showClinicalProducts ? <ClinicalProductsEditTab path="clinicalProducts" /> : null}
        <PMSEditTab path="pmsDetails" />
        <EmployersEditTab path="employers" />
      </ScrollableTabbedForm>
    </Edit>
  );
}
