import { NotificationSettingsDTO } from "../../../types/notifications";

const formatNotificationSettings = (
  notificationSettings: NotificationSettingsDTO["data"][] = []
) =>
  notificationSettings[0] ?? {
    attributes: {
      emailFromAddress: "",
      emailFromName: "",
      twilioSmsSenderName: "",
    },
  };

export default formatNotificationSettings;
