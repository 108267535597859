import React from "react";
import { BooleanInput } from "ra-ui-materialui";
import { FormDataConsumer,  } from "react-admin";
import { get, useFormContext } from "react-hook-form";
import { EditTabProps } from "../../../types/utilities";
import { FormTab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { defaultEmployerSettings } from "../../../constants/employer";
import { EmployerTableInput } from "../inputs/EmployerTableInput";
import { EmployersFileInput } from "../inputs/EmployersFileInput";
import { ListNewEmployers } from "../fields/ListNewEmployers";
import { DownloadExistingEmployersButton } from "../buttons/DownloadExistingEmployersButton";
import useLazyLoadEditTab from "../../../hooks/useLazyLoadEditTab";

const otherOptionSource = "employerSettings.attributes.allowOtherEmployeeOption";


export function EmployersEditTab(props: EditTabProps) {
  const form = useFormContext();
  const shouldDisplay = useLazyLoadEditTab(props.path);

  const resetOtherOption = () => {
    form.setValue(otherOptionSource, defaultEmployerSettings.attributes.allowOtherEmployeeOption);
  };

  return (
    <FormTab {...props} label="Employers" alignItems="normal">
      {
        !shouldDisplay ? null :
          <>
            <Heading level={2}>Employers Configuration</Heading>
            <BooleanInput
              source="employerSettings.attributes.employerRequiredOnBookings"
              label="Should capture employer"
              onChange={ () => {
                resetOtherOption();
              }}
            />
            <FormDataConsumer>
              {({ formData }) => {
                const isEmployerRequiredOnBooking = get(
                  formData,
                  "employerSettings.attributes.employerRequiredOnBookings"
                );

                if (!isEmployerRequiredOnBooking) {
                  return null;
                }

                return (
                  <BooleanInput
                    source={otherOptionSource}
                    label="Allow 'other' option"
                  />
                );
              }}
            </FormDataConsumer>
            <EmployerTableInput />
            <ListNewEmployers />
            <EmployersFileInput />
            <DownloadExistingEmployersButton/>
          </>
      }
    </FormTab>
  );
}
