import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { Form, maxLength, ReferenceInput, required } from "react-admin";
import { TextInput } from "ra-ui-materialui";
import { FieldValues, useWatch } from "react-hook-form";
import { FormDataConsumer } from "ra-core";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";
import AutocompleteInput from "../../inputs/AutocompleteInput";

interface EmployerRecord {
  name: string;
  code: string;
  clinicalProductId?: string;
}

export const BaseEmployerDialog = (props: {
  isOpened: boolean;
  onClose: () => void;
  title: string;
  submitButtonText: string;
  record: EmployerRecord;
  submit: (values: FieldValues) => void;
}) => {
  const { isOpened, onClose, title, submitButtonText, record, submit } = props;
  const clinicalProductsEnabled = useWatch({ name: "attributes.clinicalProductsEnabled" });
  const clinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);
  const showClinicalProducts = clinicalProductsEnabled && (clinicalProductsEnabledFlag || clientClinicalProductsEnabledFlag);

  const clientId = useWatch({name: "id"});

  return (
    <Dialog
      open={isOpened}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      data-cy="employer-dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <Form record={record} onSubmit={submit}>
        <DialogContent>
          <TextInput
            label={"Name"}
            source="name"
            fullWidth
            validate={[required(), maxLength(512)]}
          />
          {showClinicalProducts ?
            <ReferenceInput
              source={"clinicalProductId"}
              reference="clinicalProducts"
              filter={ clientClinicalProductsEnabledFlag ? {clientId} : { availableForClient: clientId }}
              required
            >
              <AutocompleteInput
                showClearButton
                id={"id"}
                sx={{ display: "inline-flex" }}
                label="Clinical Products"
                optionText="attributes.name"
                filterToQuery={query => ({
                  name: query,
                })}
              />
            </ReferenceInput> : null}
        </DialogContent>
        <FormDataConsumer>
          {({ formData }) => {
            const isSubmitDisabled =  record.name == formData.name &&
              ((showClinicalProducts) && record.clinicalProductId === formData.clinicalProductId);

            return (<DialogActions>
              <Button onClick={onClose} aria-label="Cancel">
                Cancel
              </Button>
              <Button type="submit" variant="contained" disabled={isSubmitDisabled} data-testid={"submitChangeEmployerButton"}>
                {submitButtonText}
              </Button>
            </DialogActions>);
          }}
        </FormDataConsumer>
      </Form>
    </Dialog>
  );
};
