import { GetListParams } from "ra-core";
import { list } from "../index";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";


async function listPatientClinicalServices(params: GetListParams) {
  const { filter } = params;

  const { clientId, patientRecordId, clientIdentifier, employerId } = filter;
  const includes = ["clinicalService", "clinicalProduct"];

  const query = {
    "overrideClientIdentifier": clientIdentifier,
    "overrideEmployerId": employerId,
    include: includes.join(","),
  };

  const queryParams = shallowlyRemoveEmptyValues(query);


  const endpoint = `/clients/${clientId}/patientRecords/${patientRecordId}/clinicalServices`;

  const data = await list(endpoint, { params: queryParams });

  return data;
}

export default listPatientClinicalServices;
