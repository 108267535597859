import React from "react";
import { Create, SimpleForm } from "ra-ui-materialui";
import { Heading } from "../ui/Heading";
import { RaRecord, useNotify, useRedirect } from "ra-core";
import { BooleanInput, FormDataConsumer, NumberInput, ReferenceInput, TextInput } from "react-admin";
import { nonEmpty } from "../../validators/nonEmpty";
import AutocompleteInput from "../inputs/AutocompleteInput";

const transform = (newRecord: RaRecord) => {
  const isSpeciality = isNaN(newRecord.serviceId);

  if (isSpeciality) {
    newRecord.attributes.specialty = newRecord.serviceId
  }
  else {
    newRecord.attributes.clinicalServiceId = newRecord.serviceId;
  }

  return newRecord;

}


export function ResourcePoolCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  function onSuccess(record: RaRecord) {
    notify("Resource pool was created successfully");
    redirect(`/resourcePools/${record.id}/show`);
  }

  return (
    <Create
      mutationOptions={{
        onSuccess,
      }}
      transform={transform}
      title="Create Resource Pool"
    >
      <SimpleForm
        warnWhenUnsavedChanges
        mode="onChange"
      >
        <Heading level={2}>Shift Details</Heading>
        <TextInput
          required
          validate={nonEmpty("Resource pool require a name")}
          source="attributes.name"
          label="Name"
        />
        <ReferenceInput
          source={"serviceId"}
          reference="clinicalServicesWithSpecialities"
          filter={{ deliveryType: "Synchronous" }}
          required
        >
          <AutocompleteInput
            required
            id={"serviceId"}
            sx={{ display: "inline-flex" }}
            label="Service"
            optionText="name"
            filterToQuery={query => ({
              name: query
            })}
            isRequired
            groupBy={(choice) => choice.group}
            validate={nonEmpty("Select the category or a clinical service.")}
          />
        </ReferenceInput>
        <BooleanInput source="attributes.isEnabled" label="Is Enabled" />
        <BooleanInput defaultValue={false} source="attributes.isGloballyAvailable" label="Can be released" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return( <NumberInput
              {...rest}
              label="Release duration in minutes"
              source="attributes.globallyAvailableDuration"
              min={45}
              defaultValue={0}
              disabled={!formData?.attributes?.isGloballyAvailable}
            />);
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}
