import { BooleanInput, Datagrid, TextField } from "ra-ui-materialui";
import React from "react";
import { FormTab } from "../../Tabs";
import { EditTabProps } from "../../../types/utilities";
import { Heading } from "../../ui/Heading";
import { ClinicalProductTableInput } from "../inputs/ClinicalProductTableInput";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";
import { FunctionField, ListContextProvider, useGetList, useList } from "react-admin";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { AdornedField } from "../../fields/AdornedField";
import TooltipAdornment from "../../booking/TooltipAdornment";
import { booleanInputAdornmentStyles } from "../../../theme";
import { resetClinicalProductsServiceUsageCountersMessage } from "../../../constants/tooltipMessages";
import useLazyLoadEditTab from "../../../hooks/useLazyLoadEditTab";
import { useWatch } from "react-hook-form";


export function ClinicalProductsEditTab(props: EditTabProps) {
  const clientClinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);
  const shouldDisplay = useLazyLoadEditTab(props.path);
  const clinicalProductsEnabled = useWatch({ name: "attributes.clinicalProductsEnabled" });

  return (
    <FormTab label={"Clinical Products"} {...props} alignItems="normal">
      {!shouldDisplay ? null :
        <Box>
          <Heading level={2}>Clinical Products Configuration</Heading>
          <BooleanInput
            source="attributes.clinicalProductsEnabled"
            label="Enable clinical products"
          />
        {clinicalProductsEnabled ?
          <Box>
            <AdornedField
              style={{ width: "fit-content" }}
              source="attributes.resetUsageCountOnClinicalProductChange"
              label="Reset clinical products service usage counters"
              Field={BooleanInput}
              addLabel={false}
              adornment={
                <TooltipAdornment
                  sx={booleanInputAdornmentStyles}
                  text={resetClinicalProductsServiceUsageCountersMessage}
                />
              }
            />
            <BooleanInput
        label="Allow paid services when the allowance limit is exceeded"
        source="attributes.allowPaidServicesWhenAllowanceExceeded"
      />
      {clientClinicalProductsEnabled ?
        <ClinicalProductTableInput /> :
        <ClinicalProductsList />}
        </Box> : null
        }
        </Box>
      }
    </FormTab>
  );
}

function ClinicalProductsList() {
  const { id: clientId } = useParams();
  const {data, isLoading } = useGetList('clinicalProducts', {
    filter: {
      clientId
    },
    meta: {
      includes: ['clientIdentifiers,employers']
    }
  })
  const listContext = useList({data, isLoading})

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        bulkActionButtons={false}
      >
        <TextField label={"Clinical Product"} source={"attributes.name"}/>
        <FunctionField label={"Client Identifiers"} render={(record: any) => {
          return record.clientIdentifiers?.map((r: any) => <Typography key={r.id}>{r.attributes?.clientIdentifier}</Typography>);
        }}/>
        <FunctionField label={"Employers"} render={(record: any) => {
          return record.employers?.map((r: any) => <Typography key={r.id}>{r.attributes?.name}</Typography>);
        }}/>
      </Datagrid>
    </ListContextProvider>
  )
}
