import React, { useState } from "react";
import { useGetList, useGetOne } from "ra-core";
import { ListContextProvider, useList } from "react-admin";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "ra-ui-materialui";
import { Colors, NeutralColors, WarningColors } from "../../../theme";
import { useFormContext, useWatch } from "react-hook-form";
import { Allowances, ProductDetails } from "../../patient/tabs/ProductShowTab";

export interface ShowPatientAllowanceDialogProps {
  clientId: string;
  patientRecordId: string;
  clientIdentifier: string;
  employerId: string;
}


export const ShowPatientAllowanceDialog = (props: ShowPatientAllowanceDialogProps) => {
  const { clientId, patientRecordId, clientIdentifier, employerId } = props;
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const { data: client } = useGetOne("clients", { id: clientId });

  if (!client?.attributes?.clinicalProductsEnabled) return null;

  return (
    <Box>
      <Tooltip title={"Show Patient Allowances"}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setIsDialogOpened(true)}
        >
          Patient Allowances
        </Button>
      </Tooltip>
      <PatientAllowanceDialog
        isOpened={isDialogOpened}
        onClose={() => setIsDialogOpened(false)}
        title={"Patient Allowances"}
        patientRecordId={patientRecordId}
        clientId={clientId}
        clientIdentifier={clientIdentifier}
        employerId={employerId}
      />
    </Box>
  )
}

interface PatientAllowanceDialogProps {
  isOpened: boolean;
  onClose: () => void;
  title: string;
  clientId: string;
  patientRecordId: string;
  clientIdentifier: string;
  employerId: string;
}


export const PatientAllowanceDialog = (props: PatientAllowanceDialogProps) => {
  const { isOpened, onClose, title, clientId, patientRecordId, clientIdentifier, employerId } = props;
  const [componentInitialized, setComponentInitialized] = React.useState<boolean>(false);
  const currentProductId = useWatch({name: "currentProductId"});
  const clinicalProductChanged = useWatch({name: "clinicalProductChanged"});
  const { setValue } = useFormContext();
  const { data, isLoading } = useGetList("patientClinicalServices",
    {
      filter: {  clientId, patientRecordId, clientIdentifier, employerId },
    },
    {
      onSuccess: ((response) => {
        const clinicalProductId = response?.data?.[0]?.clinicalProducts?.[0]?.id;
        if (!componentInitialized) {
          setValue("currentProductId", clinicalProductId);
          setComponentInitialized(true);
        } else {
          setValue("clinicalProductChanged", clinicalProductId != currentProductId);
        }
     })
    }
  );
  const listContext = useList({ data, isLoading });

  return (
    <Dialog
      open={isOpened}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h2" sx={{ margin: 0 }}>
          {title}
        </Typography>
      </DialogTitle>
        <DialogContent>
          <ListContextProvider value={listContext}>
            <Stack>
              {clinicalProductChanged ?
                <Alert
                  severity={"warning"}
                  sx={{
                    color: NeutralColors.Black,
                    backgroundColor: WarningColors.LightYellow,
                    margin: "15px 30px 0px 30px",
                    borderRadius: 5,
                }}
              >
              <Typography>
                Note: The product was changed during this booking, and the allowances below reflect the update.
              </Typography>
              </Alert>: null}
              <ProductDetails />
              <Allowances/>
            </Stack>
          </ListContextProvider>
        </DialogContent>
      <DialogActions>
        <Button onClick={onClose} aria-label="Cancel">
          Close
        </Button>
        <Button type="submit" variant="contained">
          <Link style={{color: Colors.White}} target="_blank" to={`/patientRecords/${patientRecordId}:${clientId}/bookings`} >
            Open bookings
          </Link>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
