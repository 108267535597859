import { useState } from "react";
import { useParams } from "react-router-dom";

export default function(tabPath: string | undefined) {
  const [rendered, setRendered] = useState<boolean>(false);
  const location = useParams();

  if (!rendered && (location['*'] === tabPath || !tabPath)) {
    setRendered(true);
  }

  return rendered;
}