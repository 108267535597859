import { defaultTheme } from "react-admin";
import { Theme } from "@mui/material/styles";
import {
  MenuItemLinkClasses,
  SidebarClasses,
  SimpleFormIteratorClasses,
} from "ra-ui-materialui";
import merge from "lodash/merge";
import { FeatureFlagService } from "./featureFlags";
import { TOGGLE_NEW_BRANDING } from "./featureFlags/flags";

const NeutralColors = {
  Black: "#000000",
  SecondaryBlack: "#1A2637",
  DarkGrey: "#46505B",
  Grey: "#878787",
  MediumGrey: "#C4C4C4",
  LightGrey: "#E8E8E8",
  LighterGrey: "#F5F5F5",
  LightestGrey: "#FAFAFA",
  White: "#FFFFFF",
};

const PrimaryColors = {
  SuperLightBlue: "#E1E8F3",
  LightBlue: "#9ABAEC",
  LighterBlue: "#DEF1FF",
  MidBlue: "#6193E9",
  BrightBlue: "#2D71F6",
  Blue: "#4B5A76",
};

let AlertColors = {
  SuperLightCoral: "#FDF5F6",
  LightCoral: "#EEACAF",
  Red: "#F4616B",
  DarkRed: "#DF5A63",
  DarkerRed: "#C0313B",
};

const WarningColors = {
  LightYellow: "#fff0c3",
  Yellow: "#F9DEA7",
  DarkYellow: "#F2C25F",
  Mustard: "#CC8801",
};

const SuccessColors = {
  LightGreen: "#9AE5BA",
  Green: "#76C496",
  BrightGreen: "#2EBB77",
};

const InfoColors = {
  Cyan: "#79C0EE",
  DarkCyan: "#3B86C6",
};

const OtherColors = {
  LightPurple: "#A77DBB",
  Purple: "#84519B",
};

export const IdentityVerificationColor = {
  primaryColor: '#00B0FF',
  secondaryColor: '#FFFFFF',
  fontColor: '#000000'
};

const featureFlags = FeatureFlagService.getInstance();
let isNewBrandingEnabled = featureFlags.getFlag(
  TOGGLE_NEW_BRANDING
);
isNewBrandingEnabled = isNewBrandingEnabled ? true : true

if (isNewBrandingEnabled) {
  /*
  NeutralColors = {
    Black: "#1C1C1C",
    SecondaryBlack: "#323232",
    DarkGrey: "#777777",
    Grey: "#8D8D8D",
    MediumGrey: "#A4A4A4",
    LightGrey: "#BABABA",
    LighterGrey: "#D2D2D2",
    LightestGrey: "#E8E8E8",
    White: "#FFFFFF",
  };
  
  PrimaryColors = {
    SuperLightBlue: "#1Ba9FF",
    LightBlue: "#009CF9",
    LighterBlue: "#0087D9",
    MidBlue: "#0073B8",
    BrightBlue: "#0067A6",
    Blue: "#005C93"
  };
  
  AlertColors = {
    SuperLightCoral: "#F97E86",
    LightCoral: "#F86E77",
    Red: "#F75E68",
    DarkRed: "#F53D4A",
    DarkerRed: "#F41D2B",
  };
  
  WarningColors = {
    Yellow: "#FED270",
    DarkYellow: "#FECD60",
    Mustard: "#FEC23D",
  };
  
  SuccessColors = {
    LightGreen: "#2CBDBF",
    Green: "#26A4A6",
    BrightGreen: "#229495",
  };
  */
  
  AlertColors = {
    SuperLightCoral: "#F97E86",
    LightCoral: "#F86E77",
    Red: "#F75E68",
    DarkRed: "#F53D4A",
    DarkerRed: "#F41D2B",
  };
}

export {
  NeutralColors,
  PrimaryColors,
  AlertColors,
  WarningColors,
  SuccessColors,
  InfoColors,
  OtherColors,
};

export const Colors = {
  ...NeutralColors,
  ...PrimaryColors,
  ...AlertColors,
  ...WarningColors,
  ...SuccessColors,
  ...InfoColors,
  ...OtherColors,
};

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = merge({}, defaultTheme, {
  palette: {
    primary: {
      dark: Colors.Blue,
      main: Colors.BrightBlue,
      light: Colors.MidBlue,
    },
    secondary: {
      light: Colors.LightCoral,
      main: Colors.Red,
      dark: Colors.DarkRed,
    },
    success: {
      light: Colors.Green,
      main: Colors.BrightGreen,
      dark: Colors.BrightGreen,
    },
    warning: {
      main: Colors.DarkYellow,
      light: Colors.Yellow,
      dark: Colors.Mustard,
    },
    text: {
      secondary: Colors.Blue,
    },
    background: {
      default: Colors.White,
    },
    grey: {
      100: Colors.LightestGrey,
      200: Colors.LighterGrey,
      300: Colors.LightGrey,
      400: Colors.MediumGrey,
      500: Colors.Grey,
      600: Colors.DarkGrey,
      700: Colors.SecondaryBlack,
    },
    commmon: {
      white: Colors.White,
    },
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          position: "static"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: "var(--weight-bold)",
          fontSize: "var(--34px)",
          color: Colors.Black,
          marginBottom: 0,
          marginTop: "16px",
          lineHeight: "var(--42px)",
        },
        h2: {
          fontWeight: "var(--weight-bold)",
          fontSize: "var(--26px)",
          color: Colors.BrightBlue,
          marginBottom: 8,
          marginTop: 16,
          lineHeight: "var(--34px)",
        },
        h3: {
          fontWeight: "var(--weight-semibold)",
          fontSize: "var(--22px)",
          color: Colors.Black,
          marginBottom: "0.4em",
          marginTop: "1em",
          lineHeight: "var(--30px)",
        },
        h4: {
          fontWeight: "var(--weight-bold)",
          fontSize: "var(--20px)",
          color: Colors.Black,
          marginBottom: "0.4em",
          marginTop: "1em",
          lineHeight: "var(--28px)",
        },
        h6: {
          fontWeight: 500,
        },
        body1: {
          fontSize: "var(--16px)",
          lineHeight: "var(--24px)",
          fontWeight: "var(--weight-regular)",
        },
        body2: {
          fontSize: "var(--16px)",
          lineHeight: "var(--24px)",
          fontWeight: "var(--weight-regular)",
        },
        button: {
          textAlign: "center",
          lineHeight: "var(--20px)",
          fontSize: "var(--14px)",
          fontWeight: "var(--weight-regular)",
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 18,
        thickness: 4,
      },
      styleOverrides: {
        root: {
          color: Colors.White,
          marginRight: "8px",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: Colors.White,
          opacity: "0.9",
          "&.Mui-active": {
            color: Colors.White,
            opacity: "1",
            "& .MuiTableSortLabel-icon": {
              color: "inherit",
              opacity: "inherit",
            },
          },
          "&:hover": {
            color: Colors.White,
            opacity: "1",
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          width: "fit-content",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: `${Colors.Black}99`,
          textOverflow: "clip",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: "170px",
          "& .MuiIconButton-label": {
            color: Colors.White,
          },
        },
        body: {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: "170px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: Colors.Black,
          opacity: 0.87,
        },
      },
    },
    MuiSwitch: {
      thumb: {
        backgroundColor: Colors.White,
      },
      root: {
        "& + .MuiFormControlLabel-label": {
          display: "flex",
          alignItems: "center",
          height: "48px",
        },
      },
      switchBase: {
        "&.Mui-checked + .MuiSwitch-track": {
          opacity: 1,
        },
      },
      colorPrimary: {
        "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: Colors.BrightBlue,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        paddingBottom: 4,
      },
    },
    MuiDialogActions: {
      root: {
        gap: 16,
      },
      spacing: {
        padding: "16px 24px",
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: Colors.BrightBlue,
        "&.Mui-checked": {
          color: Colors.BrightBlue,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "500",
          minWidth: "160px",
          borderRadius: `6px 6px 0 0`,
          whiteSpace: "nowrap",
          opacity: 1,
          backgroundColor: Colors.LightGrey,
          color: Colors.DarkGrey,
          transition: "background-color 0.05s",
          "&:hover": {
            backgroundColor: Colors.MediumGrey,
          },
          "&.Mui-selected": {
            transition: "none",
            backgroundColor: Colors.BrightBlue,
            color: Colors.White,
            "&:hover": {
              backgroundColor: Colors.BrightBlue,
            },
          },
        },
      },
      scroller: {
        background: Colors.White,
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          maxWidth: "248px",
        },
        fullWidth: {
          maxWidth: "100%",
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: Colors.Blue,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          gap: 8,
        },
      },
    },
    MuiSelected: {
      styleOverrides: {
        root: {
          color: Colors.White,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          backgroundColor: Colors.White,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          border: "1px solid transparent",
          "&.button-add": {
            borderColor: Colors.BrightBlue,
            "&:hover": {
              boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.12)",
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            fontSize: "var(--10px)",
            lineHeight: "var(--12px)",
            color: Colors.DarkerRed,
          },
        },
      },
    },
    MuiFilledInput: {
      // Targets inputText
      styleOverrides: {
        root: {
          fontSize: "var(--16px)",
          fontWeight: "var(--weight-medium)",
          lineHeight: "var(--24px)",
        },
      },
    },
    RaSelectInput: {
      styleOverrides: {
        root: {
          minWidth: "248px",
        },
      },
    },
    RaSelectArrayInput: {
      styleOverrides: {
        root: {
          minWidth: "248px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: "248px",
          ["& + .Mui-error"]: {
            maxWidth: "200px",
          },
        },
      },
    },
    RaLabeled: {
      styleOverrides: {
        root: {
          marginBottom: "1rem",
          // Targets formLabel
          ["& .RaLabeled-label"]: {
            fontSize: "var(--12px)",
            lineHeight: "var(--14px)",
            color: Colors.DarkGrey,
            fontWeight: "var(--weight-regular)",
          },

          // Targets fieldText
          "& .RaLabeled-label + .MuiTypography-body1, & .RaLabeled-label + .MuiTypography-body2":
            {
              fontSize: "var(--16px)",
              fontWeight: "var(--weight-medium)",
              lineHeight: "var(--24px)",
            },
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          color: Colors.DarkGrey,
          opacity: 0.7,
          ["& svg"]: {
            fill: Colors.DarkGrey,
          },
          [`&.${MenuItemLinkClasses.active}`]: {
            background: Colors.MidBlue,
            color: Colors.White,
            opacity: 1,
            ["& svg"]: {
              fill: Colors.White,
            },
            "&:hover": {
              background: Colors.LightBlue,
            },
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          [`& .${SidebarClasses.fixed}`]: {
            background: Colors.White,
            borderRight: `1px solid ${Colors.LightGrey}`,
            zIndex: 1000,
          },
          [`&.${SidebarClasses.appBarCollapsed}`]: {
            marginTop: 0,
          },
        },
      },
    },
    RaReferenceField: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            textDecoration: "underline",
          },
        },
      },
    },
    RaFilterButton: {
      styleOverrides: {
        root: {
          display: "flex",
          "& .MuiButton-root": {
            minHeight: "42px",
            padding: "6px 20px",
            fontSize: "var(--16px)",

            "&.MuiButton-sizeSmall": {
              lineHeight: "var(--22px)",
            },

            border: `1px solid ${Colors.BrightBlue}`,
            "&:focus-visible": {
              outline: `1px solid ${Colors.BrightBlue}`,
            },
            "&:hover": {
              backgroundColor: Colors.SuperLightBlue,
            },
            "&:active": {
              backgroundColor: Colors.BrightBlue,
              color: Colors.White,
            },
          },
        },
      },
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          alignItems: "start",
          marginTop: 0,
          "& .MuiFormHelperText-root": {
            display: "initial",
          },
          // aligning vertically all the switch inputs in the filter
          "& .filter-field:has(.MuiSwitch-input)": {
            alignSelf: "center",
          },
        },
      },
    },
    RaSimpleFormIterator: {
      styleOverrides: {
        root: {
          marginTop: 0,
          [`& .${SimpleFormIteratorClasses.line}`]: {
            display: "flex",
            alignItems: "center",
            border: "0",
            position: "relative",
            width: "fit-content",
          },
          [`& .${SimpleFormIteratorClasses.indexContainer}`]: {
            display: "none",
          },

          [`& .${SimpleFormIteratorClasses.form}`]: {
            display: "block",

            [`& + .${SimpleFormIteratorClasses.action}`]: {
              minWidth: "fit-content",
              paddingTop: 0,
              marginLeft: "8px",
            },
          },
        },
      },
    },

    RaUserMenu: {
      styleOverrides: {
        root: {
          "& .RaUserMenu-userButton": {
            padding: "6px 8px",
            fontSize: "var(--14px)",
          },
        },
      },
    },

    RaList: {
      styleOverrides: {
        root: {
          "& .RaList-content": {
            marginTop: "10px",
          },
        },
      },
    },
    RaCheckboxGroupInputItem: {
      styleOverrides: {
        root: {
          [`& .RaCheckboxGroupInputItem-checkbox`]: {
            height: "auto",
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .RaDatagrid-headerCell": {
            backgroundColor: Colors.Blue,
            fontWeight: "bold",
            color: Colors.White,
            "& > span:not(.MuiTableSortLabel-root):not(.MuiCheckbox-root)": {
              opacity: "0.7",
            },
          },
          "& .RaDatagrid-row": {
            backgroundColor: Colors.White,
          },
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          alignItems: "stretch",
          alignSelf: "flex-start",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          height: "fit-content",
          textAlign: "center",
          lineHeight: "var(--20px)",
          fontSize: "var(--14px)",
          fontWeight: "var(--weight-regular)",
          transition: "background-color 50ms ease-out",
          minWidth: "150px",
          maxWidth: "240px",
          "&:focus": {
            "&::after": {
              content: "none",
            },
          },
          "&:hover": {
            "&::after": {
              content: "none",
            },
            "&:active::after": {
              content: "none",
            },
          },
        },
        sizeSmall: {
          minWidth: "72px",
          minHeight: "30px",
          padding: "2px 16px",
        },
        sizeMedium: {
          minHeight: "42px",
          padding: "6px 20px",
          lineHeight: "var(--22px)",
          fontSize: "var(--16px)",
        },
        contained: {
          "&.Mui-disabled": {
            background: Colors.MediumGrey,
            color: Colors.White,
          },
        },
        containedPrimary: {
          "&:focus-visible": {
            outline: `2px solid ${Colors.Blue}`,
            backgroundColor: Colors.MidBlue,
          },
          "&:hover": {
            backgroundColor: Colors.LightBlue,
          },
          "&:active": {
            backgroundColor: Colors.Blue,
          },
        },
        containedSecondary: {
          "&:focus-visible": {
            outline: `2px solid ${Colors.DarkerRed}`,
          },
          "&:hover": {
            backgroundColor: Colors.DarkRed,
          },
          "&:active": {
            backgroundColor: Colors.DarkerRed,
          },
        },
        outlined: {
          "&.Mui-disabled": {
            border: `1px solid ${Colors.MediumGrey}`,
            color: Colors.MediumGrey,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${Colors.BrightBlue}`,
          "&:focus-visible": {
            outline: `1px solid ${Colors.BrightBlue}`,
          },
          "&:hover": {
            backgroundColor: Colors.SuperLightBlue,
          },
          "&:active": {
            backgroundColor: Colors.BrightBlue,
            color: Colors.White,
          },
        },
        outlinedSecondary: {
          border: `1px solid ${Colors.Red}`,
          "&:focus-visible": {
            outline: `1px solid ${Colors.Red}`,
          },
          "&:hover": {
            backgroundColor: Colors.SuperLightCoral,
          },
          "&:active": {
            backgroundColor: Colors.Red,
            color: Colors.White,
          },
        },
        text: {
          minWidth: "70px",
          border: "1px solid transparent",
          minHeight: "23px",

          "&.Mui-disabled": {
            color: Colors.MediumGrey,
          },
        },
        textSizeSmall: {
          padding: "4px",
        },
        textPrimary: {
          "&:focus-visible": {
            border: `1px solid ${Colors.MidBlue}`,
            color: Colors.MidBlue,
          },
          "&:hover": {
            color: Colors.BrightBlue,
            background: Colors.SuperLightBlue,
          },
          "&:active": {
            backgroundColor: "transparent",
            border: "1px solid transparent",
            color: Colors.Blue,
          },
        },
        textSecondary: {
          "&:focus-visible": {
            border: `1px solid ${Colors.Red}`,
            color: Colors.Red,
          },
          "&:hover": {
            background: Colors.SuperLightCoral,
          },
          "&:active": {
            backgroundColor: Colors.White,
            border: "1px solid transparent",
            color: Colors.DarkerRed,
          },
        },
        fullWidth: {
          maxWidth: "none",
        },
        startIcon: {
          marginRight: "4px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.BrightBlue,
        },
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: Colors.Blue,
      },
    },
    RaListToolbar: {
      styleOverrides: {
        root: {
          "& > a[class*='MuiButton-root']": {
            padding: "6px 8px",
          },
        },
      },
    },
    RaShow: {
      styleOverrides: {
        root: {
          "& .MuiPaper-elevation1": {
            boxShadow: "none",
            backgroundColor: Colors.LighterGrey,
          },
        },
      },
    },
    RaCreate: {
      styleOverrides: {
        root: {
          "& .MuiPaper-elevation1": {
            boxShadow: "none",
            backgroundColor: Colors.LighterGrey,
          },
        },
      },
    },

    RaEdit: {
      styleOverrides: {
        root: {
          "& .MuiPaper-elevation1": {
            boxShadow: "none",
            backgroundColor: Colors.LighterGrey,
          },
          "& .RaEdit-card": {
            overflow: "initial",
          },
        },
      },
    },
    RaTabbedForm: {
      content: {
        paddingBottom: "1em",
      },
    },
    RaTabbedShowLayout: {
      styleOverrides: {
        content: {
          paddingBottom: "1rem",
        },
      },
    },
    RaFileInput: {
      root: {
        "& > div": {
          marginBottom: "0",
        },
      },
    },
  },
});

export const customStyles = {
  dropZone: {
    flex: 1,
    display: "flex",
    flexDirection: "column" as const,
    cursor: "pointer",
    alignItems: "center",
    padding: 4,
    borderWidth: "2px",
    borderRadius: "6px",
    borderColor: Colors.BrightBlue,
    borderStyle: "dashed",
    backgroundColor: Colors.White,
    color: Colors.BrightBlue,
    fontWeight: 500,
    marginBottom: 1,
    outline: "none",
    transition: "backgroundColor 0.1s ease-in-out",
    textAlign: "center" as const,
    width: "100%",
    minWidth: "325px",
    "& > p": {
      margin: "0",
    },
    ["&:focus"]: {
      backgroundColor: Colors.LightestGrey,
    },
    ["&:hover"]: {
      backgroundColor: Colors.LightestGrey,
    },
  },
  dropZoneDisabled: {
    cursor: "not-allowed",
    opacity: 0.8,
    ["&:focus"]: {
      backgroundColor: Colors.White,
    },
    ["&:hover"]: {
      backgroundColor: Colors.White,
    },
  },
  // A neat CSS trick: https://stackoverflow.com/questions/44793453/how-do-i-add-a-top-and-bottom-shadow-while-scrolling-but-only-when-needed
  horiztonalScrollableShadows: {
    overflow: "auto",
    background: `
    linear-gradient(90deg, white 30%, rgba(255,255,255,0)),
    linear-gradient(90deg, rgba(255,255,255,0), white 70%) 0 100%,
    radial-gradient(farthest-side at 0% 50%, rgba(0,0,0, 0.2), white),
    radial-gradient(farthest-side at 100% 50%, rgba(0,0,0, 0.2), white) 0 100%;
    `,
    backgroundRepeat: "no-repeat",
    backgroundColor: "transparent",
    backgroundPosition: "top left, top right, top left, top right",
    backgroundSize: "40px 100%, 40px 100%, 20px 100%, 20px 100%",
    backgroundAttachment: "local, local, scroll, scroll",
  },
} as const;

export const booleanInputAdornmentStyles = {
  marginBottom: "22px",
};

export const textInputAdornmentStyles = {
  marginBottom: "20px",
};

export const smallButtonAdornmentStyles = { 
  marginTop: "1em" 
};

export const mediumButtonAdornmentStyles = { 
  marginTop: "1.25em" 
} 

export const innerGroupStyles = {
  padding: 3,
  backgroundColor: "grey.200",
  borderRadius: "20px",
  mb: 3,
  "& ul > li": {
    marginBottom: 2,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
};

// hides arrows that increment/decrement input value
export const numberInputHideArrows = {
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
};

export default theme;
