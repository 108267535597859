import extractIds from "../../utilities/extractIds";
import { create, remove, update } from "..";
import { ClientClinicianPoolsDTO } from "../../types/resourcePools";


type Args = {
  clientId: string;
  clientClinicianPools: ClientClinicianPoolsDTO["data"][];
  oldClientClinicianPools: ClientClinicianPoolsDTO["data"][];
};
async function updateClientClinicalPools(args: Args){
  const {clientId, clientClinicianPools, oldClientClinicianPools} = args;
  const updateAndCreateRequests: Promise<any>[] = [];
  const deleteRequests: Promise<any>[] = [];

  const oldIds = extractIds(oldClientClinicianPools);
  const ids = extractIds(clientClinicianPools);

  const toUpdate = oldIds.filter(id => ids.includes(id));
  const toCreate = clientClinicianPools.filter(({ id }) => !id);
  const toDelete = oldClientClinicianPools.filter(x => !ids.includes(x.id));

  toDelete.forEach(record => {
    const endpoint = `/ms/clients/${clientId}/clientClinicianPools/${record.id}`;

    const request = remove(endpoint);
    deleteRequests.push(request);
  })

  await Promise.all(deleteRequests);

  toCreate.forEach(record => {
    const createEndpoint = `/ms/clients/${clientId}/clientClinicianPools`;
    const body = {
      data: {
        type: "clientClinicianPools",
        attributes: {
          resourcePoolId: record.attributes.resourcePoolId,
          isExclusive: record.attributes.isExclusive
        }
      }
    }

    const request = create(createEndpoint, body);

    updateAndCreateRequests.push(request);
  });

  toUpdate.forEach(toUpdateId => {
    const toUpdateClientClinicalPool = clientClinicianPools.find(({ id }) => id === toUpdateId);
    const oldClientClinicalPool = oldClientClinicianPools.find(({ id }) => id === toUpdateId);

    if (toUpdateClientClinicalPool!.attributes.isExclusive === oldClientClinicalPool!.attributes.isExclusive) {
      return;
    }

    const endpoint = `/ms/clients/${clientId}/clientClinicianPools/${toUpdateClientClinicalPool!.id}`;
    const body = {
      data: {
        id: toUpdateClientClinicalPool!.id,
        type: "clientClinicianPools",
        attributes: {
          isExclusive: toUpdateClientClinicalPool!.attributes.isExclusive
        }
      }
    };

    const request = update(endpoint, body);
    updateAndCreateRequests.push(request);

  })

  await Promise.all(updateAndCreateRequests);
}

export default updateClientClinicalPools;
