import { ShowTabProps } from "../../../types/utilities";
import { FormTab } from "../../form/CustomTabForm/FormTab";
import { Heading } from "../../ui/Heading";
import React from "react";
import { Group } from "../../layout/Group";
import { useGetList } from "ra-core";
import {
  Datagrid,
  Labeled,
  ListContextProvider,
  RaRecord,
  TextField,
  useList,
  useListContext,
  useRecordContext,
} from "react-admin";
import get from "lodash/get";
import { Box, Grid, Typography } from "@mui/material";
import { getStringDate } from "../../client/helpers/getStringDate";
import { formatDate } from "../../../utilities/formatDate";
import { Stack } from "../../layout/Stack";
import { FunctionField } from "ra-ui-materialui";
import { baseClinicalProductMessage, howPatientLinkedToProductMessage } from "../../../constants/tooltipMessages";
import TooltipAdornment from "../../booking/TooltipAdornment";

export function ProductShowTab(props: ShowTabProps) {
  const record = useRecordContext(props);

  const patientRecordId = get(record, "patientId");
  const clientId = get(record, "clients[0].id");

  const { data, isLoading } = useGetList("patientClinicalServices",
    {
      filter: {  clientId, patientRecordId },
    },
  );
  const listContext = useList({ data, isLoading });

  const clinicalProductsEnabled = get(record, "clients[0].attributes.clinicalProductsEnabled");
  if (!clinicalProductsEnabled) return null;

  return (
    <FormTab
      {...props}
      label="Product"
      editable="hidden"
    >
      <ListContextProvider value={listContext}>
        <Stack>
          <ProductDetails />
          <Allowances/>
        </Stack>
      </ListContextProvider>
    </FormTab>
  )
}


export function ProductDetails() {
  const list = useListContext();
  if (!list.data) return null;

  const productName = list?.data?.[0]?.clinicalProducts?.[0]?.attributes?.name;
  const productLinkageType = list?.data?.[0]?.attributes?.productLinkageType;

  return (
    <Group heading={<Heading level={2}>Product Details</Heading>}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Labeled label="Linked Product">
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography>{productName ?? "No linked product"}</Typography>
              <TooltipAdornment text={howPatientLinkedToProductMessage} />
            </Box>
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label="Linked By">
            <Typography>
              {productLinkageType ?? "N/A"}
            </Typography>
          </Labeled>
        </Grid>
      </Grid>
    </Group>
  )
}

export function Allowances() {
  const list = useListContext();
  if (!list.data) return null;

  const renewalEvery = list?.data?.[0]?.clinicalProducts?.[0]?.attributes?.maxUsageTimeFrame;
  const nextRenewalDate = list?.data?.[0]?.clinicalProducts?.[0]?.attributes?.nextRenewalDate;
  const formattedNextRenewalDate = nextRenewalDate ? formatDate(nextRenewalDate, false) : "N/A";
  const showBaseProductMessage = list.data?.length === 1 && !list?.data?.[0]?.attributes?.productLinkageType;

  return (
    <Group heading={<Heading level={2}>Allowances</Heading>}>
      {showBaseProductMessage ? <Typography>{baseClinicalProductMessage}</Typography> : null}
      <Grid container spacing={2}>
        {!showBaseProductMessage ?
          <Grid item xs={6}>
            <Labeled label="Allowance Renews Every">
              <Typography>
                {renewalEvery ? getStringDate(renewalEvery.day, renewalEvery.month) : "Not provided"}
              </Typography>
            </Labeled>
          </Grid> : null
        }
        {!showBaseProductMessage ?
          <Grid item xs={6}>
            <Labeled label="Next Renewal Date">
              <Typography>
                {formattedNextRenewalDate}
              </Typography>
            </Labeled>
          </Grid> : null
        }
        <Grid item xs={12}>
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            label="Service"
            sortable={false}
            render={(record: RaRecord) => {
              return record?.attributes?.specialty ?? record?.clinicalServices?.[0]?.attributes?.name;
            }}
          />
          <TextField
            label="Usage Type"
            source="attributes.allowanceType"
            sortable={false}
          />
          <FunctionField
            label="Allowances Given"
            sortable={false}
            render={(record: RaRecord) => {
              return record?.attributes?.maximumAllowance ?? "Unlimited";
            }}
          />
          <FunctionField
            label="Current Left"
            sortable={false}
            render={(record: RaRecord) => {
              return record?.attributes?.allowanceLeft ?? "Unlimited";
            }}
          />
        </Datagrid>
        </Grid>
      </Grid>
    </Group>
  )
}
