import React from "react";
import { Typography } from "@mui/material";
import { CheckboxGroupInput, TextInput } from "ra-ui-materialui";
import { notificationTypeNames } from "../../../constants/constants";
import { EditTabProps } from "../../../types/utilities";
import { FormTab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { NotificationSetting } from "../inputs/NotificationSettingInput";
import { EmailInput } from "../../inputs/EmailInput";
import { required } from "ra-core";
import useLazyLoadEditTab from "../../../hooks/useLazyLoadEditTab";

export function NotificationEditTab(props: EditTabProps) {
  const shouldDisplay = useLazyLoadEditTab(props.path);

  return (
    <FormTab {...props} label="Notifications">
      {
        !shouldDisplay ? null :
        <>
          <TextInput
            label="Email Sender Name"
            source="notificationSettings.attributes.emailFromName"
            validate={required()}
          />
          <EmailInput
            label="Sender Email"
            source="notificationSettings.attributes.emailFromAddress"
            validate={required()}
          />
          <TextInput
            label="SMS Sender Name"
            source="notificationSettings.attributes.twilioSmsSenderName"
          />
          <Heading level={2}>Notifications</Heading>
          <CheckboxGroupInput
            label="Notification Channels"
            source="notificationChannelSettings"
            choices={[
              { id: "Email", name: "Email" },
              { id: "SMS", name: "SMS" },
            ]}
          />
          <Typography variant="body1" sx={{ width: "100%" }}>
            Note: SMS notifications are only available for: Consultation Reminder,
            Consultation About To Start, Booking Confirmation
          </Typography>
          <Heading level={3}>Notification Types</Heading>
          {Object.keys(notificationTypeNames).map(notificationType => {
            return (
              <NotificationSetting
                key={notificationType}
                notificationType={notificationType}
                name={notificationTypeNames[notificationType]}
              />
            );
          })}
        </>
      }
    </FormTab>
  );
}
