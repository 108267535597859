import recursiveIsObjectEmpty from "../../utilities/recursiveIsObjectEmpty";

type ErrorMessage = string;

interface GpSurgeryValidation {
  name?: ErrorMessage;
  email?: ErrorMessage;
  "address.line[0]"?: ErrorMessage;
  "address.city"?: ErrorMessage;
  "address.postalCode"?: ErrorMessage;
  "address.country"?: ErrorMessage;
}

const emailPattern =
  /^[^\s@]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)*\.[a-zA-Z]{2,}$/;

export const nhsEmailDomains = [
  "nhs.net",
  "nhs.uk",
  "nhs.scot",
  "wales.nhs.uk",
  "hscni.net",
];

export const allowedProdDomains = [...nhsEmailDomains, "healthhero.com"];

export const allowedNonProdDomains = [...allowedProdDomains, "mailosaur.net"];

const isAllowedEmail = (email: string, allowedDomains: string[]) => {
  const domain = email.split("@")[1];
  return allowedDomains.some(
    allowedDomain =>
      domain === allowedDomain || domain.endsWith(`.${allowedDomain}`)
  );
};

export function validateCreateGpSurgery(values: any) {
  const isProdEnv = process.env.REACT_APP_ENV === "prod";
  const errors: GpSurgeryValidation = {};

  // return errors;

  if (!values.name || values.name.trim() === "") {
    errors["name"] = "Please enter a surgery name";
  }

  if (!values.email || values.email.trim() === "") {
    errors["email"] = "Please enter an email address";
  } else if (!emailPattern.test(values.email.trim())) {
    errors["email"] = "Please enter a valid email address";
  } else if (
    values.address?.country === "GB" &&
    !isAllowedEmail(
      values.email,
      isProdEnv ? allowedProdDomains : allowedNonProdDomains
    )
  ) {
    const allowedDomains = isProdEnv
      ? allowedProdDomains
      : allowedNonProdDomains;
    errors[
      "email"
    ] = `Email should have an allowed domain: ${allowedDomains.join(", ")}`;
  }

  if (!values.address.line[0] || values.address.line[0].trim() === "") {
    errors["address.line[0]"] = "Please enter a first line of address";
  }

  if (!values.address.city || values.address.city.trim() === "") {
    errors["address.city"] = "Please enter a city";
  }

  if (!values.address.postalCode || values.address.postalCode.trim() === "") {
    errors["address.postalCode"] = "Please enter a postal code";
  }

  if (!values.address.country || values.address.country.trim() === "") {
    errors["address.country"] = "Please select a country";
  }

  return recursiveIsObjectEmpty(errors) ? {} : errors;
}
