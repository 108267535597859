import React, { useState } from "react";
import { Edit, RaRecord, useNotify } from "react-admin";
import validateForm from "./validatePatientEditForm";
import { GeneralTab } from "./tabs/GeneralTab";

import {
  EditStateContextProvider,
  useEditStateContext,
} from "../../context/EditStateContext";
import { BookingsShowTab } from "./tabs/BookingsShowTab";
import { DependantFields } from "./DependantFields";
import { MessageThreadShowTab } from "./tabs/MessageThreadShowTab";
import { TermsShowTab } from "./tabs/TermsShowTab";
import { PatientEditActions } from "./PatientActions";
import { TabLayoutView } from "../form/CustomTabForm/TabLayoutView";
import { FormTab } from "../form/CustomTabForm/FormTab";
import { PatientToolbar } from "./PatientToolbar";
import { PatientNotesDTO } from "../../types/patients";
import cloneDeep from "lodash/cloneDeep";
import { INVALID_CLIENT_IDENTIFIER } from "../../api/errors";
import { PatientTitle } from "./PatientTitle";
import { OnlinePharmacyShowTab } from "./tabs/OnlinePharmacyShowTab";
import { useFlag } from "../../featureFlags";
import {
  TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS,
  TOGGLE_ENABLE_CLINICAL_PRODUCTS,
  TOGGLE_ENABLE_MESSAGE_DOCTOR,
} from "../../featureFlags/flags";
import { BOOKING_EMPLOYER_OTHER_IDENTIFIER } from "../../constants/employer";
import { ProductShowTab } from "./tabs/ProductShowTab";

function transformGpInformation(values: RaRecord) {
  const { attributes } = values;
  const { gp } = attributes;

  const phoneNumber = gp?.surgery?.phoneNumber || null;
  const email = gp?.surgery?.email || null;

  return {
    ...attributes.gp,
    surgery: {
      ...attributes.gp.surgery,
      phoneNumber,
      email,
    },
  };
}

function transformPharmacyInformation(values: RaRecord) {
  const { attributes } = values;
  const { nominatedPharmacy } = attributes;

  const phoneNumber = nominatedPharmacy?.phoneNumber || null;
  const email = nominatedPharmacy?.email || null;

  return {
    ...attributes.nominatedPharmacy,
    phoneNumber,
    email,
  };
}

function transformNotes(values: RaRecord) {
  const { notes, prevNotes } = values;
  if (!notes?.length) return notes;

  const clonedNotes = cloneDeep(notes);
  const modifiedNotes = clonedNotes.map((note: PatientNotesDTO["data"]) => {
    const isNewAttachmentsEmpty = !note.newAttachments;
    const isFileEmpty = !note.files?.length;

    const [previousNote] = (prevNotes ?? []).filter(
      (prevNote: PatientNotesDTO["data"]) => prevNote.id === note.id
    );

    const isPreviousFileEmpty = !previousNote?.files;

    if (isPreviousFileEmpty && isFileEmpty) {
      delete note.files;
    }

    if (isNewAttachmentsEmpty) {
      delete note.newAttachments;
    }

    return {
      ...note,
    };
  });

  return modifiedNotes;
}

export function PatientEditInner() {
  const notify = useNotify();
  const { updateState } = useEditStateContext();
  const [isOverrideModalOpen, setOverrideModalState] = useState(false);

  const messageDoctorEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR);

  const clinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);

  const showClinicalProductTab = clinicalProductsEnabled || clientClinicalProductsEnabled;

  const onSuccess = () => {
    setOverrideModalState(false);
    updateState("show");
    notify("Changes to patient were saved successfully");
  };

  const onFailure = (err: any) => {
    if (err.body?.code === INVALID_CLIENT_IDENTIFIER) {
      return setOverrideModalState(true);
    }

    notify(err.message, { type: "error" });
  }

  const transform = (values: RaRecord) => {
    const transformedGpInformation = transformGpInformation(values);
    const transformedPharmacyInformation =
      transformPharmacyInformation(values);
    const transformedNotes = transformNotes(values);

    const {prevIdentityVerificationStatus, identityVerificationStatus, failedIdentityVerificationsCounter} = values.attributes;
    const failedIdVerificationCounter = prevIdentityVerificationStatus === "Blocked" && identityVerificationStatus === "NotStarted"
      ? 0
      : failedIdentityVerificationsCounter

    const employerId = values.attributes.employerId;

    return {
      ...values,
      attributes: {
        ...values.attributes,
        employerId: !employerId || employerId === BOOKING_EMPLOYER_OTHER_IDENTIFIER ? null : employerId,
        gp: transformedGpInformation,
        nominatedPharmacy: transformedPharmacyInformation,
        failedIdentityVerificationsCounter: failedIdVerificationCounter
      },
      notes: transformedNotes,
    };
  }

  return (
    <Edit
      title={<PatientTitle />}
      actions={<PatientEditActions />}
      mutationOptions={{ onSuccess, onError: onFailure }}
      mutationMode="pessimistic"
      transform={transform}
    >
      <TabLayoutView
        validate={validateForm}
        Toolbar={PatientToolbar}
      >
        <GeneralTab
          path="general"
          open={isOverrideModalOpen}
          onClose={() => setOverrideModalState(false)}
          transform={transform}
        />
        <BookingsShowTab path="bookings" />
        <FormTab label="Dependants" path="dependants" editable="hidden">
          <DependantFields />
        </FormTab>
        {messageDoctorEnabled ? (<MessageThreadShowTab path="messages" />) : <></>}
        <TermsShowTab path="terms" />
        <OnlinePharmacyShowTab path="onlinePharmacy" />
        {showClinicalProductTab ? <ProductShowTab path="product" /> : <></>}
      </TabLayoutView>
    </Edit>
  );
}

export function PatientEdit() {
  return (
    <EditStateContextProvider>
      <PatientEditInner />
    </EditStateContextProvider>
  );
}
