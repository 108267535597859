import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useGetManyReference } from "ra-core";
import { RecommendedService } from "./RecommendedService";
import { SignpostingSymptomItem } from "./SignpostingSymptomItem";
import { useFormContext } from "react-hook-form";
import { SignpostingSymptom, SignpostingSymptomService } from "../../../../types/symptomSignposting";

export interface SymptomSignpostingProps {
  clientId: number,
}

export function SymptomSignposting({ clientId }: SymptomSignpostingProps) {
  const { setValue } = useFormContext();

  const { data: signpostingItems, isLoading } = useGetManyReference(
    "symptomSignposting", {
      id: clientId,
      target: "traversalId"
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );

  const [selectedSymptom, setSelectedSymptom] = useState<SignpostingSymptom | null>(null);
  const [selectedService, setSelectedService] = useState<SignpostingSymptomService | null>(null);

  if (isLoading || !signpostingItems) {
    return null;
  }


  const onSymptomClick = (item: any) => {
    setSelectedSymptom(item);
    setSelectedService(item.signpostingSymptomServices[0]);
    setValue("signpostingService", item.signpostingSymptomServices[0]);

  };

  const onServiceClick = (item: any) => {
    setSelectedService(item);
    setValue("signpostingService", item);
  };


  return (
    <Box data-testId={"symptomSignposting"}>
      <Grid
        container
        sx={{
          paddingRight: "16px",
          maxWidth: "932px",
          marginRight: "auto",
          marginLeft: "auto"
        }}
        spacing={2}
      >
        {signpostingItems?.map((item: any) => (
          <SignpostingSymptomItem
            key={item.id}
            item={item}
            onClick={() => onSymptomClick(item)}
            selected={selectedSymptom === item}
          />))}
      </Grid>

      {
        selectedSymptom &&
        <Grid
          container
          sx={{
            paddingRight: "16px",
            maxWidth: "932px",
            marginRight: "auto",
            marginLeft: "auto",
            marginTop: "15px"
          }}
          spacing={2}
        >
          {
            selectedSymptom.signpostingSymptomServices
              .map((item: any, index: number) =>
                <RecommendedService
                  key={item.id} item={item}
                  onClick={() => onServiceClick(item)}
                  selected={item === selectedService}
                  recommended={index === 0}
                />)
          }
        </Grid>
      }
    </Box>);

}


