import { GetListParams } from "react-admin";
import listClinicalServices from "../clinicalServices/listClinicalServices";
import { specialtyChoices } from "../../constants/constants";

async function listClinicalServiceWithSpeciality(params: GetListParams) {
  const res = await listClinicalServices(params);
  const {filter} = params;
  const { excludedSpecialties = [], name = "" } = filter;
  const filteredSpecialties = specialtyChoices
    .filter(x => !excludedSpecialties.includes(x.id) && x.name.toLowerCase().includes(name.toLowerCase()));


  const result = [
    ...filteredSpecialties.map(speciality => ({...speciality, group: "Specialties" })),
    ...res.data.map((x: any) => ({ id: x.id, name: x.attributes.name, group: "Clinical Services" }))
  ];

  return {
    total: result.length,
    data: result
  };
}

export default listClinicalServiceWithSpeciality;
