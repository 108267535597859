import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useTrackPageView() {
  const location = useLocation();

  const { pathname } = location;

  // eslint-disable-next-line lodash/prefer-noop
  useEffect(() => {
    // Previously we used Matomo to track page views here based on location.pathname and window.document.title
  }, [pathname]);

  return null;
}
