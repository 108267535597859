import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  AppBar,
  Layout as RALayout,
  LayoutProps,
  Sidebar as RASidebar,
} from "react-admin";
import { BackButton } from "./buttons/BackButton";
import { HelpLink } from "./HelpLink";
import { Menu } from "./Menu";
import { styled, Typography } from "@mui/material";
import { useTrackPageView } from "../hooks/useTrackPageView";
import { SchedulerBookingStateDisplay } from "./SchedulerBookingStateDisplay";
import logo from "../static/logo-white.svg";

function Fragment(props: any) {
  return <span {...props}>{props.children}</span>;
}

const StyledLayout = styled(RALayout)({
  minWidth: "inherit",
  "& #main-content": {
    padding: "0 50px",
  },
});

const StickyAppBar = (props: any) => (
  <AppBar {...props} container={Fragment}>
    <Typography style={{ flex: 1 }} variant="h6" color="inherit">
      <img src={logo} alt="Health Hero" height="50" style={{verticalAlign: 'middle'}} />
    </Typography>
  </AppBar>
);

const Sidebar = (props: any) => {
  return <RASidebar {...props} />;
};

export function Layout(props: LayoutProps) {
  useTrackPageView();

  return (
    <>
      <StyledLayout
        {...props}
        menu={Menu}
        appBar={StickyAppBar}
        sidebar={Sidebar}
      >
        <BackButton />
        <Typography variant="h1" id="react-admin-title" />
        {props.children}
        <HelpLink />
      </StyledLayout>
      <SchedulerBookingStateDisplay />
      <ReactQueryDevtools
        initialIsOpen={false}
        toggleButtonProps={{ style: { width: 20, height: 30 } }}
      />
    </>
  );
}
