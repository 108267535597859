import React, { useEffect } from "react";
import get from "lodash/get";
import { useGetOne } from "ra-core";
import { ReferenceInput } from "ra-ui-materialui";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { useFormContext } from "react-hook-form";

export interface ClientEmployerInputProps {
  clientId: string|number;
  source: string;
}

export function ClientEmployerInput(props: ClientEmployerInputProps) {
  const { clientId, source} = props;
  const { setValue } = useFormContext();

  const { data: employerSettings } = useGetOne("employerSettings", {
    id: clientId,
  });
  const employerRequiredOnBookings = get(
    employerSettings,
    "attributes.employerRequiredOnBookings"
  );
  const allowOtherEmployeeOption = get(
    employerSettings,
    "attributes.allowOtherEmployeeOption"
  );

  useEffect(() => {
    setValue("employerRequiredOnBookings", employerRequiredOnBookings);
  }, [employerRequiredOnBookings]);

  if (!employerRequiredOnBookings) {
    return null;
  }

  return (
    <ReferenceInput
      alwaysOn
      source={source}
      reference="employers"
      allowEmpty
      format={(el: any) => el}
      filter={{ clientId, includeOther: allowOtherEmployeeOption }}
      required
    >
      <span style={{ display: "inline-block" }}>
        <AutocompleteInput
          label="Employer"
          filterToQuery={query => ({
            name: query,
            clientId,
            includeOther: allowOtherEmployeeOption,
          })}
          placeholder="Type to search by name..."
          optionText={record => record.attributes.name}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          emptyText="Clear employer"
          required
          data-testid={"employerInput"}
        />
      </span>
    </ReferenceInput>
  );
}
