import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";
import { ChipField, FunctionField, ListContextProvider, useList } from "react-admin";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Datagrid, ReferenceField } from "ra-ui-materialui";
import { ClientEmployerChange } from "../../types/clientEmployer";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../featureFlags/flags";
import { useFlag } from "../../featureFlags";
import { ChangeAction } from "../../types/common";
import { useWatch } from "react-hook-form";


type Props = {
  onResetChangesClicked: () => void;
  onUndoClicked: (change: ClientEmployerChange) => void;
  data: ClientEmployerChange[];
};

const actionIconsSx = { marginRight: "0.2em" };

const ActionIcons = [
  <AddIcon sx={actionIconsSx} key={"addIcon"} />,
  <EditIcon sx={actionIconsSx} key={"editIcon"} />,
  <RemoveIcon sx={actionIconsSx} key={"removeIcon"} />
];
const actionColors = ["#ccf8d6", "#f8eed0", "#ffd6d6"];

export const EditEmployersChangesList = (props: Props) => {
  const changesListContext = useList({ data: props.data });
  const clinicalProductsEnabled = useWatch({ name: "attributes.clinicalProductsEnabled" });
  const clinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabledFlag = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);
  const showClinicalProducts = clinicalProductsEnabled && (clinicalProductsEnabledFlag || clientClinicalProductsEnabledFlag);

  return (
    <>
      {props.data.length > 0 &&
        <Box display="flex" justifyContent={"space-between"} marginTop={"20px"}>
          <Typography variant={"h4"}>
            Pending Changes
          </Typography>
          <Button
            data-testid={"resetChangesButton"}
            sx={{ marginTop: "10px" }}
            variant={"outlined"}
            onClick={props.onResetChangesClicked}
            size={`small`}
          >
            Reset changes
          </Button>
        </Box>
      }
      <ListContextProvider value={changesListContext}>
        <Datagrid
          bulkActionButtons={false}
          title={"Changes"}
          sx={{
            "& .RaDatagrid-row td:last-child": { textAlign: "right" }
          }}
        >
          <FunctionField label="Action" render={(rec: ClientEmployerChange) =>
            <Box
              display={"flex"}
              sx={{
                backgroundColor: actionColors[rec.action],
                borderRadius: "7px",
                width: "100px",
                padding: "5px"
              }}
            >
              {ActionIcons[rec.action]}
              {ChangeAction[rec.action]}
            </Box>} />
          <FunctionField label="Name" render={(rec: ClientEmployerChange) =>
            <>
              {rec.action === ChangeAction.Edited && rec.payload.attributes.name !== rec.oldPayload?.attributes.name &&
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ textDecoration: "line-through", marginRight: "5px" }}
                >
                  {rec.oldPayload?.attributes.name}
                </Typography>}
              <Typography
                component="span"
                variant="body1"
              >
                {rec.payload.attributes.name}
              </Typography>
            </>
          }/>
          {showClinicalProducts ?
            <ReferenceField
              label="Clinical Products"
              reference="clinicalProducts"
              link={false}
              source="payload.attributes.clinicalProductId">
                <ChipField
                  source="attributes.name"
                  sx={{ marginRight: "5px" }} />
            </ReferenceField> : null}
          <FunctionField
            render={(rec: ClientEmployerChange) =>
              <Button
                data-testid={"undoButton"}
                variant={"contained"}
                onClick={() => props.onUndoClicked(rec)}
                size={"small"}
              >
                Undo
              </Button>}
          />
        </Datagrid>
      </ListContextProvider>
    </>
  );
};
