import React from "react";
import { ArrayField, Datagrid, SingleFieldList, TextField } from "ra-ui-materialui";
import { BooleanField } from "../../fields/BooleanField";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { NoRecordsMessage } from "../messages/NoRecordsMessage";
import { ChipField, Pagination, ReferenceManyField } from "react-admin";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";
import { useFlag } from "../../../featureFlags";

export function EmployersShowTab(props: ShowTabProps) {
  const clinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);

  return (
    <Tab {...props} label="Employers">
      <Heading level={2}>Employers Configuration</Heading>
      <BooleanField
            label="Should capture employer"
            source="employerSettings.attributes.employerRequiredOnBookings"
            emptyText="No"
          />
      <BooleanField
            label="Allow 'other' option"
            source="employerSettings.attributes.allowOtherEmployeeOption"
            emptyText="No"
          />      

      <ReferenceManyField
        reference="clientEmployers"
        target="client.Id"
        label={false}
        pagination={<Pagination />}
        sort={{field: "name", order: "ASC"}}
      >
        <Datagrid
          bulkActionButtons={false}
          empty={<NoRecordsMessage message={"No employers are associated with this client."} />}
        >
          <TextField label="Name" source="attributes.name" sortBy={"name"}/>
          {clinicalProductsEnabled || clientClinicalProductsEnabled ?
            <ArrayField label={"Clinical Products"} source="clinicalProducts" sortable={false}>
              <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
                <ChipField source="attributes.name" />
              </SingleFieldList>
            </ArrayField>
            : null}
        </Datagrid>
      </ReferenceManyField>
    </Tab>
  );
}
