import React from "react";
import { DateInput, Filter, NumberInput, ReferenceInput, TextInput } from "react-admin";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { numberInputHideArrows } from "../../theme";
import { validateNumberGreaterThan } from "../../validators/validateNumberGreaterThan";
import { validateGuid } from "../../validators/validateGuid";
import { validateNumberLowerThan } from "../../validators/validateNumberLowerThan";
import { MAX_INT32 } from "../../constants/constants";

export function PatientFilter(props: any) {
  return (
    <Filter {...props}>
      <ReferenceInput
        sort={{ field: "id", order: "ASC" }}
        alwaysOn
        source="clients"
        reference="clients"
      >
        <AutocompleteInput
          label="Client Contract"
          filterToQuery={query => ({ name: query })}
          placeholder="Search by client..."
          shouldRenderSuggestions={(query: string) => query.length > 1}
          source="id"
          optionText="attributes.name"
          helperText={false}
        />
      </ReferenceInput>
      <DateInput label="Date of Birth" source="dateOfBirth" alwaysOn />
      <TextInput
        label="First Name"
        source="firstName"
        alwaysOn
        autoComplete="off"
      />
      <TextInput
        label="Last Name"
        source="lastName"
        alwaysOn
        autoComplete="off"
      />
      <TextInput label="Email" source="email" autoComplete="off" />
      <NumberInput
        label="Booking ID"
        source="bookingId"
        autoComplete="off"
        sx={numberInputHideArrows}
        validate={value => {
          if (value === "" || value === null) return;
          return validateNumberGreaterThan(value, 0) ?? validateNumberLowerThan(value, MAX_INT32);
        }}
      />
      <TextInput
        label="User ID"
        source="userId"
        autoComplete="off"
        validate={value => {
          if (value === "" || value === null) return;
          return validateGuid(value);
        }}
      />
    </Filter>
  );
}
