import React from "react";
import Button from "@mui/material/Button";
import { useCreate, useGetManyReference, useRecordContext } from "ra-core";

export function DownloadExistingEmployersButton() {
  const record = useRecordContext();
  const { id } = record;

  const { data, isLoading } = useGetManyReference("clientEmployers", {
    target: "clientId",
    id,
    pagination: { page: 1, perPage: 10 }
  });

  const [download, { isLoading: isDownloading }] = useCreate(
    "downloadClientEmployers",
    {
      data: { clientId: id },
    }
  );

  const employers = Object.values(data ?? {});

  const hasExistingEmployers =
    !isLoading && employers.length ? true : false;

  return (
    <Button
      disabled={!hasExistingEmployers || isDownloading}
      variant="contained"
      color="primary"
      aria-label="download-existing-employers"
      onClick={() => download()}
    >
      Download Existing Employers
    </Button>
  );
}
