import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { version } from "./version";
import isEmpty from "lodash/isEmpty";
import { getConfig } from "./config";
import type { AppConfig } from "./config";

export function initMonitoring() {
  const config = getConfig();
  const datadogEnv = setDatadogEnv(config);
  const datadogVer = setDatadogVer();

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
    site: config.dataDog.site,
    service: config.dataDog.service,
    env: datadogEnv,
    version: datadogVer,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });

  datadogLogs.setGlobalContext({
    host: config.dataDog.host,
    backend_env: config.dataDog.environment,
  });

  initRumSession();
}

function initRumSession() {
  const config = getConfig();
  const datadogEnv = setDatadogEnv(config);
  const datadogVer = setDatadogVer();

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID ?? "",
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
    site: config.dataDog.site,
    service: config.dataDog.service,
    env: datadogEnv,
    version: datadogVer,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    traceSampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask",
    allowedTracingUrls: [/https:\/\/.*\.healthhero\.com/],
  });

  datadogRum.setGlobalContext({
    host: config.dataDog.host,
    backend_env: config.dataDog.environment,
  });
}

export function setSessionUser(userId: string, email: string, role: string) {
  datadogRum.setUser({
    id: userId,
    email,
    role,
  });
}

export const restartRumSession = () => {
  const user = datadogRum.getUser();
  if (isEmpty(user)) return;

  datadogRum.stopSession();
  initRumSession();
};

const setDatadogEnv = (config: AppConfig): string => {
  return process.env.NODE_ENV === "development"
    ? "local"
    : config.dataDog.environment;
};

const setDatadogVer = (): string => {
  return process.env.NODE_ENV == "development" ? `local_${version}` : version;
};
