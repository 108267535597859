import React from "react";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";
import { Datagrid, Filter, List, RaRecord, TextInput } from "react-admin";
import { useParams } from "react-router-dom";
import { ArrayField, FunctionField, SelectInput, SingleFieldList, TextField } from "ra-ui-materialui";
import { Chip, Typography } from "@mui/material";
import { NoRecordsMessage } from "../messages/NoRecordsMessage";
import { BooleanField } from "../../fields/BooleanField";
import { Heading } from "../../ui/Heading";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";
import { getStringDate } from "../helpers/getStringDate";


function ClinicalProductsListFilter(props: any) {
  return (
    <Filter {...props}>
      <TextInput
        label="Name"
        source="name"
        alwaysOn
        autoComplete="off"
      />
      {props.clientClinicalProductsEnabled &&
        <TextInput
          label="Code"
          source="code"
          alwaysOn
          autoComplete="off"
        />
      }
      {props.clientClinicalProductsEnabled &&
        <SelectInput
          label={"Enabled"}
          source="isEnabled"
          alwaysOn
          choices={[{ id: true, name: "Enabled" }, { id: false, name: "Disabled" }]}
        />
      }
    </Filter>
  );
}

export function ClinicalProductsShowTab(props: ShowTabProps) {
  const { id: clientId } = useParams();
  const clientClinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);

  return (
    <Tab label={"Clinical Products"} {...props}>
      <Heading level={2}>Clinical Products</Heading>
      <BooleanField
        label="Enable clinical products"
        source="attributes.clinicalProductsEnabled"
      />
      <BooleanField
        label="Reset clinical products service usage counters"
        source="attributes.resetUsageCountOnClinicalProductChange"
      />
      <BooleanField
        label="Allow paid services when the allowance limit is exceeded"
        source="attributes.allowPaidServicesWhenAllowanceExceeded"
      />
      <List
        title={" "} // no title
        resource="clinicalProducts"
        filters={<ClinicalProductsListFilter />}
        actions={false}
        empty={false}
        filter={{ clientId }}
        queryOptions={{
          meta: {
            includes: ["clientIdentifiers,employers"],
          },
        }}
      >
        <Datagrid
          bulkActionButtons={false}
          empty={<NoRecordsMessage message={"No clinical products are associated with this client."} />}
        >
          <TextField
            label={"Name"}
            source={"attributes.name"}
            sortable={false}
          />
          {clientClinicalProductsEnabled &&
            <TextField
              label="Code"
              source="attributes.code"
              sortable={false}
            />
          }
          {clientClinicalProductsEnabled &&
            <BooleanField
              label="Enabled"
              source="attributes.isEnabled"
              sortable={false}
            />
          }
          {clientClinicalProductsEnabled &&
            <FunctionField
              render={(rec: RaRecord) => {
                const {day, month} = rec.attributes.maxUsageTimeFrame;
                return getStringDate(day, month)
              }}
              label="Allowance Renewal Date"
              emptyText="Not specified"
              hideLabel
              sortable={false}
            />
          }
          {clientClinicalProductsEnabled &&
            <ArrayField label={"Services"} source="attributes.clinicalProductServices" sortable={false}>
              <SingleFieldList sx={{ padding: "10px 0", maxWidth: "300px" }} linkType={false}>
                <FunctionField label="Services" render={(rec: any) =>
                  <Chip
                    sx={{ margin: "4px" }}
                    label={`${rec.specialty ?? rec.clinicalService.name} - ${rec.maxUsageCount ?? "Unlimited"}`}
                  />
                } />
              </SingleFieldList>
            </ArrayField>
          }
          <FunctionField label={"Client Identifiers"} render={(record: any) => {
            return record.clientIdentifiers?.map((r: any) => <Typography key={r.id}>{r.attributes?.clientIdentifier}</Typography>);
          }}/>
          <FunctionField label={"Employers"} render={(record: any) => {
            return record.employers?.map((r: any) => <Typography key={r.id}>{r.attributes?.name}</Typography>);
          }}/>
        </Datagrid>
      </List>
    </Tab>);
}
