import React from "react";
import { Filter, TextInput, BooleanInput, NumberInput } from "react-admin";
import { numberInputHideArrows } from "../../theme";
import { validateNumberGreaterThan } from "../../validators/validateNumberGreaterThan";
import { validateNumberLowerThan } from "../../validators/validateNumberLowerThan";

export function ClientFilter(props: any) {
  return (

    <Filter {...props}>
      <TextInput
        label="Contract Name" source="name" alwaysOn />
      <NumberInput
        sx={numberInputHideArrows}
        label="Client ID"
        source="id"
        alwaysOn
        validate={value => {
          if (value === "" || value === null) return;
          return validateNumberGreaterThan(value, 0) ?? validateNumberLowerThan(value, 1_000_000);
        }}
      />
      <BooleanInput
        label="Show Discarded Clients"
        source="shouldShowDiscardedClients"
        alwaysOn
      />
    </Filter>
  );
}
