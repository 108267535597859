import pureSplice from "../../utilities/pureSplice";

type ErrorMessage = string;

interface ClientClinicianPoolsError {
  attributes: {
    resourcePoolId?: ErrorMessage;
  }
  resourcePools: {
    service?: ErrorMessage
  }[]
}

interface Errors {
  clientClinicianPools: ClientClinicianPoolsError[];
}

interface Values {
  clientClinicianPools: ClientClinicianPool[];
}

interface ClientClinicianPool {
  id?: string;
  attributes: {
    resourcePoolId?: string;
  };
  resourcePools?: {
    service?: string|number
  }[]
}

function validateResourcePools(values: Values) {
  const { clientClinicianPools } = values;
  const errors: Errors = {
    clientClinicianPools: [],
  };

  if (clientClinicianPools) {
    clientClinicianPools.forEach((pool, index: number) => {
      if (!pool || pool.id) return;

      const error: ClientClinicianPoolsError = {
        attributes: {},
        resourcePools: [],
      };

      const serviceError = validateService(pool, index, clientClinicianPools);

      if (serviceError) {
        error.resourcePools[0] = {
          service: serviceError,
        }
      }

      const resourcePoolError = validateResourcePool(pool);

      if (resourcePoolError) {
        error.attributes = {
          resourcePoolId: resourcePoolError,
        }
      }


      errors.clientClinicianPools[index] = error;
    });
  }

  return errors;
}

function validateService(clientClinicianPool: ClientClinicianPool, index: number, other: ClientClinicianPool[]) {
  if (!clientClinicianPool.resourcePools?.[0]?.service) {
    return "Please select a service"
  }

  if (clientClinicianPool.resourcePools?.[0]?.service) {
    const specialty = clientClinicianPool.resourcePools?.[0].service;
    const previousResourcePools = pureSplice(other, index);

    const poolExists = previousResourcePools.find(
      ({ resourcePools }) => resourcePools?.[0].service === specialty
    );

    if (poolExists) {
      return "A pool already exists with this service"
    }
  }
}

function validateResourcePool(clientClinicianPool: ClientClinicianPool,) {
  if (clientClinicianPool.resourcePools?.[0]?.service && !clientClinicianPool.attributes?.resourcePoolId) {
    return "Please select a resource pool"
  }
}

export default validateResourcePools;
