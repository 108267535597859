import { GetOneParams } from "ra-core";
import { list } from "../gaiaClient";
import { ConsultationDocumentOutput, ConsultationDocumentOutputResponse } from "../../types/gaia";
import { FeatureFlagService } from "../../featureFlags";
import { TOGGLE_USE_NEW_GET_CONSULTATION_OUTPUT_LOGIC } from "../../featureFlags/flags";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getDocument(params: GetOneParams) {
  const { id } = params;

  const { caseRef, output } = await getOutput(id);
  const newDocs = output.documents.map(({ type, ...rest }) => {
    return {
      ...rest,
      type: type ?? "doc",
    };
  });

  const formattedData = {
    ...output,
    id,
    documents: newDocs,
    caseRef,
  };

  return {
    data: formattedData,
  };
}


async function getOutput(id: string) : Promise<{ caseRef: string, output: ConsultationDocumentOutput  }> {
  const featureFlags = FeatureFlagService.getInstance();
  const useNewLogic = featureFlags.getFlag(TOGGLE_USE_NEW_GET_CONSULTATION_OUTPUT_LOGIC);

  if (useNewLogic) {

    const [caseRef, encounterId] = String(id).split("__");

    const endpoint = `consultation-output/encounters/${encounterId}/document-info/`;

    const { data } = await list(endpoint);

    return { caseRef, output: data };
  }

  const [caseRef, startDate, endDate] = String(id).split("__");

  const queryParams = {
    startDate,
    endDate,
  };
  const filteredParams = shallowlyRemoveEmptyValues(queryParams);
  const endpoint = `consultation-output/document-info/${caseRef}`;
  const { data }: ConsultationDocumentOutputResponse = await list(endpoint, {
    params: filteredParams,
  });
  return { caseRef, output: data.consultationDocumentOutputs[0] };
}


export default getDocument;
