import React from "react";
import { ReferenceInput, TextInput } from "react-admin";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { useWatch } from "react-hook-form";

interface AppointmentFilterInputsProps {
  clientId: string,
  patientRecordId: string
}


const AppointmentFilterRescheduleInputs = ({
 clientId,
 patientRecordId
}: AppointmentFilterInputsProps) => {
  const consultationType = useWatch({ name: "consultationType" });

  return (
    <ReferenceInput
      reference="availableServices"
      source="service"
      filter={{ clientId, patientRecordId, consultationType }}
    >
      <>
        <AutocompleteInput
          label="Service"
          placeholder="Choose a service"
          optionText="name"
          filterToQuery={query => ({
            name: query,
          })}
          required
          groupBy={(choice) => choice.group}
        />
        <TextInput
          sx={{width: "260px"}}
          source="consultationType"
          label="Consultation Type"
          required
          disabled
        />
      </>
    </ReferenceInput>
  );
};

export default AppointmentFilterRescheduleInputs;
