import { Labeled, TextField, TextInput } from "ra-ui-materialui";
import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { Stack } from "../../layout/Stack";
import { Group } from "../../layout/Group";
import { Heading } from "../../ui/Heading";
import { Grid } from "@mui/material";
import { ClinicalAddressesInputs } from "../ClinicalAddressesInputs";
import { PatientDetailsInputs } from "../PatientDetailsInputs";
import { ReasonForBookingInput } from "../ReasonForBookingInput";
import { Identifier, useGetOne, useRecordContext } from "ra-core";
import { FullNameField } from "../../fields/FullNameField";
import DateField from "../../fields/DateField";
import { ReasonForMADInput } from "../ReasonForMADInput";
import { BookingAttachmentInput } from "../BookingAttachmentInput";
import { ClientSettingsDTO } from "../../../types/clientSettings";
import { VNetIntake } from "./VNetIntake";
import { isVNetCaseService, isVNetManualIntakeService } from "../helpers/vNetUtils";
import { VNetCase } from "./VNetCase";
import { ClientEmployerInput } from "../../inputs/ClientEmployerInput";
import { SelectInput } from "react-admin";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_MESSAGE_DOCTOR, TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY } from "../../../featureFlags/flags";
import { Choice } from "../../../types/common";
import { splitByCapitalLetters } from "../../../utilities/splitByCapitalLetters";

interface Props {
  appointmentId: Identifier | null;
  clientId: string;
  patientRecordId: string;
}

function getAvailableConsultationTypes(
  appointmentConsultationType: string,
  messageDoctorEnabled: boolean,
  messageDoctorCreateReplyEnabled: boolean) : Choice[] {
  let availableConsultationTypes: string[] = appointmentConsultationType === "Video" ?
    ["Phone", "Video"] :
    [appointmentConsultationType];

  if (!(messageDoctorEnabled && messageDoctorCreateReplyEnabled)) {
    availableConsultationTypes = availableConsultationTypes.filter((c: string) => c != "MessageDoctor");
  }

  return availableConsultationTypes.map((ct: string) => ({id: ct, name: splitByCapitalLetters(ct)}))
}

export function SchedulerBookingCreateForm(props: Props) {
  const { appointmentId, clientId } = props;

  const { data: appointment } = useGetOne(
    "adminAppointments",
    {
      id: appointmentId,
    },
    { enabled: Boolean(appointmentId) }
  );

  const messageDoctorEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR);
  const messageDoctorCreateReplyEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY);
  const appointmentConsultationType = appointment?.attributes?.consultationType;
  const availableConsultationTypes = getAvailableConsultationTypes(appointmentConsultationType, messageDoctorEnabled, messageDoctorCreateReplyEnabled)

  const isAppointmentMAD = appointmentConsultationType === "MessageDoctor";

  const consultationType = useWatch({ name: "consultationType" });

  const isMAD = isAppointmentMAD || consultationType === "MessageDoctor";

  const { data: client } = useGetOne("clients", { id: clientId });
  const clientSettings = client?.clientSettings as ClientSettingsDTO["data"][];
  const collectProductId =
    clientSettings?.some(
      s =>
        s.attributes.key == "CollectProductId" && s.attributes.value == "true"
    ) ?? false;

  const service = appointment?.attributes?.clinicalServiceId ?? appointment?.clinicalPractitioners[0]?.attributes?.specialties[0];

  const showManualIntake = isVNetManualIntakeService(client, service);
  const showIhcCase = isVNetCaseService(client, service);

  const record = useRecordContext();
  useEffect(() => {
    if (isAppointmentMAD) {
      record.consultationType = "MessageDoctor";
    }
  }, [isAppointmentMAD, record]);

  return (
    <>
      <Stack>
        <Group heading={<Heading level={2}>General Information</Heading>}>
          <Grid item xs={12} sm={6}>
            <Labeled label="Patient Name">
              <TextField source="patientName" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateField
              record={appointment}
              source="attributes.start"
              label="Appointment Time"
              showTime
            />
          </Grid>
          <Grid item xs={12}>
            <Labeled label="Clinical Practitioner Name">
              <FullNameField
                firstNameSource="clinicalPractitioners[0].attributes.firstName"
                lastNameSource="clinicalPractitioners[0].attributes.lastName"
                record={appointment}
              />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label="Clinical Practitioner Specialty">
              <TextField
                source="clinicalPractitioners[0].attributes.specialties[0]"
                record={appointment}
              />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            {isAppointmentMAD ? (
              <Labeled label="Consultation Type">
                <TextField
                  source="attributes.consultationType"
                  record={appointment}
                />
              </Labeled>
            ) :
              <SelectInput
                disabled={!availableConsultationTypes?.length}
                source="consultationType"
                label="Consultation Type"
                required
                choices={availableConsultationTypes}
              />
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled label="Clinical Practitioner Gender">
              <TextField
                source="clinicalPractitioners[0].attributes.gender"
                record={appointment}
              />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            {isMAD ? <ReasonForMADInput /> : <ReasonForBookingInput />}
          </Grid>
          {collectProductId ? (
            <Grid item xs={12} sm={6}>
              <TextInput label="Product ID" source="productId" />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <ClientEmployerInput
              clientId={clientId}
              source="employerId"
            />
          </Grid>
          {showManualIntake &&
            <Grid item xs={12} sm={6}>
              <VNetIntake />
            </Grid>}
          {showIhcCase &&
            <Grid item xs={12} sm={6}>
              <VNetCase />
            </Grid>}
        </Group>

        {isMAD ? (
          <>
            <Group heading={<Heading level={2}>Attachments</Heading>}>
              <BookingAttachmentInput />
            </Group>
          </>
        ) : (
          <>
            <ClinicalAddressesInputs />
            <PatientDetailsInputs />
          </>
        )}
      </Stack>
    </>
  );
}
