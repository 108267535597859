import { Labeled, useRecordContext } from "react-admin";
import Typography from "@mui/material/Typography";
import React from "react";
import { Grid } from "@mui/material";
import DateField from "../fields/DateField";
import { ReferenceField } from "ra-ui-materialui";

export function CompletedDetails() {
  const record = useRecordContext();
  const { isCompleted } = record!.attributes;
  const completedById = record.completedBy?.id;

  if (!isCompleted) {
    return null;
  }

  return (
    <>
      <Grid item sm={6} xs={12}>
        <DateField
          label="Completed at"
          source={`attributes.completedAt`}
          showTime
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        {completedById &&
          <Labeled label={"Completed By"}>
            <ReferenceField
              label={"Completed By"}
              source={"completedBy.id"}
              reference={"resourceManagement"}
            >
              <CompletedBy />
            </ReferenceField>
          </Labeled>
        }
      </Grid>
    </>
  );

}

function CompletedBy() {
  const record = useRecordContext();

  const { firstName, lastName, userName } = record.attributes;

  const name = firstName && lastName ? `${firstName} ${lastName}` : userName;

  return (
    <Typography
      component="span"
      variant="body1"
    >
      {name}
    </Typography>
  );
}
