import { useRecordContext } from "ra-core";
import { FieldProps } from "ra-ui-materialui";
import { useFormContext } from "react-hook-form";

export function DebugField(props: FieldProps) {
  const record = useRecordContext();
  const formState = useFormContext();

  console.log("form state: ", formState);

  console.log("debug: ", { record, ...props });

  return null;
}
