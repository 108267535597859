import { GetListResult, GetOneParams } from "ra-core";
import getAppointmentFilters from "./getAppointmentFilters";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";
import uniqBy from "lodash/uniqBy";
import { listClinicalServices } from "../clinicalServices";
import { GetListParams } from "react-admin";
import { sortAvailableServices } from "./helpers/sortAvailableServices";
import { FeatureFlagService } from "../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../featureFlags/flags";
import { getClient } from "./index";
import listPatientClinicalServices from "./listPatientClinicalServices";
import { PatientClinicalServicesDTO } from "../../types/patientClinicalServices";

interface AppointmentFilterCategories {
  consultationType: string;
  specialty?: string;
  clinicalServiceId?: number;
  clinicalService?: {
    id: number,
    name: string,
    deliveryType: string
  }
}

export interface AvailableService {
  id: string | number,
  name: string,
  group: string,
  consultationTypes: string[],
  allowanceLeft?: number
}

const defaultSpecialties = ["GeneralPractitioner", "AdvancedNursePractitioner", "ClinicalPharmacist", "Counsellor", "Dietitian", "Psychiatrist", "Psychologist", "Physiotherapist", "SpeechAndLanguage"]
const defaultConsultationTypes =  ["Phone","Video","MessageDoctor","Onsite"];



async function listAvailableServices(
  args: GetListParams
): Promise<GetListResult> {
  let availableServices: AvailableService[] = [];
  const featureFlags = FeatureFlagService.getInstance();
  const clinicalProductsEnabledFlag = featureFlags.getFlag(
    TOGGLE_ENABLE_CLINICAL_PRODUCTS
  );

  const clientClinicalProductsEnabledFlag = featureFlags.getFlag(
    TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS
  );

  const { clientId,  name = "", consultationType = "" } = args.filter;
  const clientIdParam = { id: clientId } as GetOneParams;

  if (clinicalProductsEnabledFlag || clientClinicalProductsEnabledFlag) {
    const client = await getClient(clientIdParam);
    if (client?.data?.attributes?.clinicalProductsEnabled)
    {
      availableServices = await getProductServices(args);
      availableServices = filterServices(availableServices, name, consultationType);
      sortAvailableServices(availableServices)

      return {
        data: availableServices,
        total: availableServices.length
      };
    }
  }

  const appointmentFilters = await getAppointmentFilters(clientIdParam);

  if (!appointmentFilters.data?.attributes?.categories?.length) {
    availableServices = await getAllServices(args);
    availableServices = filterServices(availableServices, name, consultationType);
    sortAvailableServices(availableServices)

    return {
      data: availableServices,
      total: availableServices.length
    };
  }

  availableServices = getServicesFromAppoitnmentFilterCategories(appointmentFilters.data.attributes.categories)
  availableServices = filterServices(availableServices, name, consultationType);
  sortAvailableServices(availableServices)

  return {
    data: availableServices,
    total: availableServices.length
  };
}

const getAllServices = async (args: GetListParams): Promise<AvailableService[]> => {
  const clinicalServices = await listClinicalServices({
    ...args,
    filter: {...args.filter, deliveryType: "Synchronous"}
  } as GetListParams)
  const availableServices = clinicalServices.data.map((cs: any) => {
    return {
      id: cs.id,
      name: splitByCapitalLetters(cs.attributes.name),
      group: "Clinical Services",
      consultationTypes: defaultConsultationTypes
    } as AvailableService
  });

  const specialties = defaultSpecialties
    .map((s: string) => {
      return {
        id: s,
        name: splitByCapitalLetters(s),
        group: "Specialties",
        consultationTypes: defaultConsultationTypes,
      } as AvailableService;
    });

  return [
    ...specialties,
    ...availableServices
  ] as AvailableService[];
}

const getServicesFromAppoitnmentFilterCategories = (appointmentFilterCategories: AppointmentFilterCategories[]): AvailableService[] => {
  const services = appointmentFilterCategories
    .map((category: AppointmentFilterCategories) => {
      if (category.specialty)
      {
        return {
          id: category.specialty,
          name: splitByCapitalLetters(category.specialty),
          group: "Specialties",
          consultationTypes: appointmentFilterCategories
            .filter((c: AppointmentFilterCategories) => c.specialty === category.specialty)
            .map((c: AppointmentFilterCategories) => c.consultationType)
        } as AvailableService;
      }
      if (category.clinicalService)
        return {
          id: category.clinicalServiceId,
          name: splitByCapitalLetters(category.clinicalService.name),
          group: "Clinical Services",
          consultationTypes: appointmentFilterCategories
            .filter((c: AppointmentFilterCategories) => c.clinicalServiceId === category.clinicalServiceId)
            .map((c: AppointmentFilterCategories) => c.consultationType)
        } as AvailableService
    }) as AvailableService[];

  return uniqBy(services, "id");
}

const getProductServices = async (args: GetListParams): Promise<AvailableService[]> => {
  const productServices = await listPatientClinicalServices(args);

  const productServicesResult = productServices
    .data.map((service: PatientClinicalServicesDTO) => {
      return {
        id: service.attributes.specialty ?? service.attributes.clinicalServiceId,
        name: service.attributes.specialty ? splitByCapitalLetters(service.attributes.specialty) : service.clinicalServices?.[0]?.attributes.name,
        group: service.attributes.specialty ? "Specialties" : "Clinical Services",
        consultationTypes: service.attributes.consultationTypes,
        allowanceLeft: service.attributes.allowanceLeft,
      } as AvailableService;
    })

  return productServicesResult;
}

const filterServices = (availableServices: AvailableService[], name: string, consultationType: string) : AvailableService[] => {
  return availableServices.filter((service: AvailableService) =>
    service.name.toLowerCase().includes(name.toLowerCase()) && (!consultationType || service.consultationTypes.includes(consultationType))) as AvailableService[];
}


export default listAvailableServices;
