import dayjs from "dayjs";

type ErrorMessage = string;

interface PatientValidation {
  clientId?: ErrorMessage;
  firstName?: ErrorMessage;
  lastName?: ErrorMessage;
  email?: ErrorMessage;
  dateOfBirth?: ErrorMessage;
  isDependant?: ErrorMessage;
  gender?: ErrorMessage;
  phoneNumber?: ErrorMessage;
  clientIdentifier?: ErrorMessage;
  identityVerificationStatus?: ErrorMessage;
  employerId?: ErrorMessage;
}
const phoneNumberRegex = new RegExp("^[+]?[0-9 ]+$");

function validatePatientDetails(values: any, validateCreateForm = false) {
  const errors: PatientValidation = {};

  if (!values.clientId && !values.isDependant) {
    errors.clientId = "A client contract is required";
  }

  if (!values.firstName) {
    errors.firstName = "The patient's first name is required";
  }

  if (!values.lastName) {
    errors.lastName = "The patient's last name is required";
  }

  if (!values.dateOfBirth) {
    errors.dateOfBirth = "The patient's age is required";
  }

  if (!values.gender) {
    errors.gender = "The patient's gender is required";
  }
  const isInvalidPhoneNumber = !phoneNumberRegex.test(values.phoneNumber);

  if (!values.phoneNumber) {
    errors.phoneNumber = "The patient's phone number is required";
  } else if (isInvalidPhoneNumber) {
    errors.phoneNumber = "The patient's phone number is in invalid format";
  }

  if (values.isDependant) {
    const sixteenYearsAgo = dayjs().subtract(16, "years");
    const isWithinDependancyAge = dayjs(sixteenYearsAgo).isBefore(
      values.dateOfBirth
    );

    // This validation happens on the patient create page
    if (!values.dependantOf && validateCreateForm) {
      errors.isDependant =
        "Please enter the email of the person to whom the patient is a dependant";
    }

    if (values.dateOfBirth && !isWithinDependancyAge) {
      errors.dateOfBirth = "Dependant must be younger than 16 years old";
    }
  }

  if (values.prevIdentityVerificationStatus === "Verified" || values.prevIdentityVerificationStatus === "Blocked") {
      if (values.identityVerificationStatus !== "NotStarted" &&
          values.identityVerificationStatus !== "Verified" &&
          values.identityVerificationStatus !== "Blocked") {
        errors.identityVerificationStatus = "Verification status can only be reset to Not Started";
      }
  }

  if (values.clientIdentifierRequired && !values.clientIdentifier && !values.isDependant) {
    errors.clientIdentifier = "The patient's policy number is required";
  }

  if (values.clientIdentifier?.length > 512) {
    errors.clientIdentifier = "Max length of the policy number is 512 characters";
  }

  if (values.employerRequiredOnBookings && !values.employerId) {
    errors.employerId = "The employer is required";
  }

  return errors;
}

export default validatePatientDetails;
