import React from "react";
import { SelectInput, useChoicesContext } from "react-admin";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";
import { Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useFlag } from "../../featureFlags";
import { TOGGLE_ENABLE_MESSAGE_DOCTOR, TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY } from "../../featureFlags/flags";
import { Choice } from "../../types/common";
import { AvailableService } from "../../api/clients/listAvailableServices";

export const getConsultationTypeChoices = (
  serviceId: string | number | undefined,
  email: string | undefined,
  data: AvailableService[],
): Choice[] => {
  if (!data) return [];

  let consultationTypes: string[] = [];

  // Service isn't selected yet - return all available types
  if (!serviceId){
    data?.map((service: AvailableService) => consultationTypes = consultationTypes.concat(service.consultationTypes));
    consultationTypes = Array.from(new Set(consultationTypes));
  }
  // Service is selected - return all available types for the service
  else {
    const service = data?.find((s: any) => s.id == serviceId);
    consultationTypes = service?.consultationTypes?.filter((ct: string) => email || ct === "Phone") ?? [];
  }

  return consultationTypes.map((ct: string) => ({ id: ct, name: splitByCapitalLetters(ct)}));
};

export const ConsultationTypeInput = () => {
  const { availableChoices, isLoading } = useChoicesContext();

  const service = useWatch({name: "service"});
  const email = useWatch({name: "email"});
  let choices = getConsultationTypeChoices(service, email, availableChoices) ?? [];

  const messageDoctorEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR);
  const messageDoctorCreateReplyEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY);

  if (!(messageDoctorEnabled && messageDoctorCreateReplyEnabled)){
    choices = choices.filter((c: Choice) => c.id != "MessageDoctor");
  }

  if (isLoading) return null;

  return (
    <>
      {!email ? (
        <Typography>
          This patient doesn't have an email address, and will not be able
          to make Video, MAD or Onsite bookings
        </Typography>
      ) : null}
      <SelectInput
        disabled={!choices?.length}
        source="consultationType"
        label="Consultation Type"
        required
        choices={choices}
      />
    </>
  );
};
