import { GetOneParams } from "react-admin";
import { get } from "..";

async function getResource(params: GetOneParams) {
  const getResourceEndpoint = `/ms/administratorUsers/${params.id}`;

  const { data } = await get(getResourceEndpoint);

  const result = Array.isArray(data) ? data[0] : data;

  const { isCsoUser } = result.attributes;

  result.role = isCsoUser ? "CSO" : "Admin";

  return { data: result };
}

export default getResource;
