import React, { useEffect } from "react";
import {
  Filter,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useListContext,
  useListSortContext,
} from "react-admin";
import { WorkQueueItemType, WorkQueueStatus } from "../../types/workQueues";
import AutocompleteInput from "../inputs/AutocompleteInput";

import { useFlag } from "../../featureFlags";
import { TOGGLE_ENABLE_MESSAGE_DOCTOR } from "../../featureFlags/flags";
import { DateInput } from "../inputs/DateInput";
import dayjs from "dayjs";
import { numberInputHideArrows } from "../../theme";
import { validateNumberGreaterThan } from "../../validators/validateNumberGreaterThan";
import { validateNumberLowerThan } from "../../validators/validateNumberLowerThan";
import { MAX_INT32 } from "../../constants/constants";

type Choice<T extends string> = {
  name: string;
  id: T;
}[];

const statusChoices: Choice<WorkQueueStatus> = [
  {
    name: "Assigned",
    id: "Assigned",
  },
  {
    name: "Completed",
    id: "Completed",
  },
  {
    name: "Unassigned",
    id: "Unassigned",
  },
];

const actionChoices: Choice<WorkQueueItemType> = [
  {
    name: "Allocation Required",
    id: "AllocationRequired",
  },
  {
    name: "Cancel Booking",
    id: "CancelBooking",
  },
  {
    name: "Follow Up Action",
    id: "FollowUpAction",
  },
  {
    name: "Manual Intervention Required",
    id: "ManualInterventionRequired",
  },
  {
    name: "Notification Failed",
    id: "NotificationFailed",
  },
  {
    name: "Send Prescription",
    id: "SendPrescription",
  },
  {
    name: "Capture Payment",
    id: "CapturePayment",
  },
  {
    name: "Send Consultation Notes To GP",
    id: "SendConsultationNotesToGP"
  },
  {
    name: "Add Patient GP Surgery",
    id: "AddPatientSurgery"
  },
  {
    name: "Form Fill Consultation Action Required",
    id: "FormFillConsultationActionRequired"
  },
  {
    name: "Send Form Fill Documents To GP",
    id: "SendFormFillDocumentsToGp"
  },
  {
    name: "ADHD Booking Too Far",
    id: "AdhdBookingTooFar"
  }
];

export function WorkQueueFilter(props: any) {
  const { filterValues, displayedFilters, setFilters, hideFilter } = useListContext();
  const { sort, setSort } = useListSortContext();
  const isCompletedStatus = filterValues.status === "Completed";

  useEffect(() =>
  {
    if (!isCompletedStatus) {
      hideFilter("completedAt");
      if (sort.field === "completedAt") {
        setSort({ field: "appointment.start", order: "ASC" });
      }
    }
    if (isCompletedStatus && !filterValues.completedAt) {
      setFilters(
        {...filterValues, completedAt: dayjs().format("YYYY-MM-DD")},
        {...displayedFilters, completedAt: true},
        false);
      setSort({ field: "completedAt", order: "DESC" });
    }
  },
  [
    displayedFilters,
    filterValues,
    hideFilter,
    isCompletedStatus,
    setFilters,
    setSort,
    sort.field
  ]);

  const messageDoctorEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR);
  const itemTypeChoices: Choice<WorkQueueItemType> = messageDoctorEnabled
    ? [...actionChoices, {
      name: "Message Doctor Allocation Required",
      id: "MessageDoctorAllocationRequired",
    }]
    : actionChoices;
  
  return (
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        source="assignedToUserId"
        reference="resourceManagement"
      >
        <AutocompleteInput
          label="Assignee"
          placeholder="Search by CSO email..."
          source="id"
          optionText="attributes.email"
          helperText={false}
          filterToQuery={query => ({
            username: query,
          })}
        />
      </ReferenceInput>
      <SelectInput
        source="status"
        label="Status"
        choices={statusChoices}
        alwaysOn
      />
      <SelectArrayInput
        source="itemType"
        label="Type"
        choices={itemTypeChoices}
        alwaysOn
      />
      <NumberInput
        alwaysOn
        label="Booking ID"
        source="bookingId"
        sx={numberInputHideArrows}
        validate={value => {
          if (value === "" || value === null) return;
          return validateNumberGreaterThan(value, 0) ?? validateNumberLowerThan(value, MAX_INT32);
        }}
      />
      {messageDoctorEnabled &&
        <NumberInput
          alwaysOn
          label="Thread ID"
          source="threadId"
          sx={numberInputHideArrows}
          validate={value => {
            if (value === "" || value === null) return;
            return validateNumberGreaterThan(value, 0) ?? validateNumberLowerThan(value, MAX_INT32);
          }}
        />}
      <TextInput alwaysOn label="PMS Case No" source="caseNumber" />
      {isCompletedStatus && 
        <DateInput  
          alwaysOn
          label="Completed Date" 
          source="completedAt"
          disableFuture
          hasHelperText={false} />}
    </Filter>
  );
}
