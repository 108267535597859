import { EditTabProps } from "../../../types/utilities";
import { BooleanInput, SelectInput, minValue, NumberInput, required } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { FormTab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { PatientIdentifiersFileInput } from "../inputs/PatientIdentifiersFileInput";
import { ListNewPatientIdentifiers } from "../fields/ListNewPatientIdentifiers";
import { ListExistingIdentifiers } from "../fields/ListExistingIdentifiers";
import { ListNewIdentifiers } from "../fields/ListNewIdentifiers";
import { ClientIdentifiersFileInput } from "../inputs/ClientIdentifiersFileInput";
import React from "react";
import { externalValidationChoices } from "../../../constants/constants";
import { DownloadExistingIdentifiersButton } from "../buttons/DownloadExistingIdentifiersButton";
import useLazyLoadEditTab from "../../../hooks/useLazyLoadEditTab";

export const ValidationEditTab = (props: EditTabProps)  => {
  const { setValue } = useFormContext()
  const isPatientValidation = useWatch({ name: "validationRules.multipoint.isEnabled" })
  const isClientIdentifierEnabled = useWatch({ name: "validationRules.clientIdentifier.isEnabled" })
  const isExternalValidation = useWatch({ name: "validationRules.clientFunction.isEnabled" })
  const requiredClientIdentifier = useWatch({ name:"attributes.clientIdentifierRequired" });
  const shouldDisplay = useLazyLoadEditTab(props.path);

  return(
    <FormTab {...props} label="Validation" alignItems="normal">
      {
        !shouldDisplay ? null :
        <>
          <Heading level={2}>Validation Rules</Heading>
          <Heading level={3}>Patient Validation</Heading>
          <BooleanInput
            source="validationRules.multipoint.isEnabled"
            label="Enable Patient Validation"
          />
          {isPatientValidation ? <PatientValidationInputs /> : null}
          <Heading level={3}>Client Validation</Heading>
          <BooleanInput
            onChange={e => {
              !e.target.checked && setValue("validationRules.clientIdentifier.isEnabled", e.target.checked);
            }}
            source="attributes.clientIdentifierRequired"
            label="Capture Client Identifier"
          />
          <BooleanInput
            disabled={!requiredClientIdentifier}
            source="validationRules.clientIdentifier.isEnabled"
            label="Enable Client Validation"
          />
          {isClientIdentifierEnabled ? <ClientValidationInputs /> : null}
          <Heading level={3}>External Validation</Heading>
          <BooleanInput
            source="validationRules.clientFunction.isEnabled"
            label="Enable External Validation"
          />
          {isExternalValidation ? <ExternalValidationInputs /> : null}
        </>
      }
    </FormTab>
  )
}

const PatientValidationInputs = () => {
  return (
    <>
      <NumberInput
        style={{ width: "300px" }}
        source="newPatientIdentifiers.attributes.minValidColumns"
        validate={[
          required("A numeric value is required"),
          minValue(1, "Value must be 1 or greater"),
        ]}
        label="Minimum required patient identifiers"
      />
      <PatientIdentifiersFileInput />

      <ListNewPatientIdentifiers />
    </>
  )
}

const ClientValidationInputs = () => {
  return (
    <>
      <ListExistingIdentifiers />
      <ListNewIdentifiers />
      <ClientIdentifiersFileInput />
      <DownloadExistingIdentifiersButton />
    </>
  )
}

const ExternalValidationInputs = () => {
  const hasRecord = useWatch({ name: "clientFunctionValidation.id" })

  return (
    <SelectInput
      disabled={hasRecord}
      validate={required("Please choose a client function")}
      source="clientFunctionValidation.clientName"
      label="External Validation Function"
      choices={externalValidationChoices}
    />
  )
}
