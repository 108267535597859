import { GetManyReferenceParams } from "react-admin";
import { list } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

async function getIdentifiers(params: GetManyReferenceParams) {
  const { pagination, id } = params;

  const { page, perPage } = pagination;

  const clientIdentifiersEndpoint = `/ms/clients/${id}/identifiers?include=clinicalProduct`;

  const pageParams = {
    "page[number]": page,
    "page[size]": perPage,
  };

  const filterParams = {
    "filter[isEnabled]": true,
  };

  const queryParams = {
    ...pageParams,
    ...filterParams,
  };

  const filteredParams = shallowlyRemoveEmptyValues(queryParams);

  const { data, total } = await list(clientIdentifiersEndpoint, {
    params: filteredParams,
  });

  return { data, total };
}

export default getIdentifiers;
