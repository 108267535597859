import React from "react";
import Papa from "papaparse";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import { useNotify } from "react-admin";
import { Box } from "@mui/material";
import { FileInput } from "../../inputs/FileInput";
import { ClientEmployerDTO } from "../../../types/clientEmployer";

const rootStyles = {
  marginBottom: 4,
  "& label": {
    padding: "32px",
  },
};

export function EmployersFileInput() {
  const notify = useNotify();
  const { setValue } = useFormContext();

  const parseCsv = (file: File) => {
    return new Promise((resolve, reject) => {
      Papa.parse<Array<string | null>>(file, {
        header: false,
        skipEmptyLines: true,
        error: e => {
          console.error(e);
          notify(
            'We had trouble processing the CSV file you uploaded. Ensure that the file is .csv, there are no titles, and the column is "name"',
            {
              type: "error",
            }
          );

          reject(e)
        },
        complete: event => {
          const parsedValues = event.data
            .filter(x => x[0])
            .map((x) => {
              const result = {} as ClientEmployerDTO["data"]["attributes"];
              x.forEach((item, index) => {
                switch (index) {
                  case 0:
                    result.name = item ?? '';
                    break;
                  case 1:
                    result.code = item ?? '';
                    break;
                }
              })

              return result;
            });

          resolve(parsedValues);
        }
      });
    })
  };


  return (
    <Box sx={rootStyles}>
      <FileInput
        label={false}
        source="newEmployers"
        onChange={file => {
          parseCsv(file).then((data) => {
            setValue("newEmployers", data);
            setValue("employersCSV", file)
          });
        }}
        placeholder="Upload a .csv with the client's employers"
        accept=".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
        sx={{ marginTop: "16px", padding: 0 }}
      />
      <Typography variant="body1">
        Note: Uploading new employers will remove existing employers if they are not present
      </Typography>
    </Box>
  );
}
