import { EditTabProps } from "../../../types/utilities";
import React from "react";
import { FormTab } from "../../Tabs";
import { Box, Typography } from "@mui/material";
import { ArrayInput } from "../../inputs/ArrayInput";
import { SimpleFormIterator } from "../../form/SimpleFormIterator";
import { FormIteratorAddButton } from "../../form/FormIteratorAddButton";
import { FormIteratorRemoveButton } from "../../form/FormIteratorRemoveButton";
import { BooleanInput, FormDataConsumer, ReferenceInput, TextInput } from "react-admin";
import AutocompleteInput from "../../inputs/AutocompleteInput";
import { ClientClinicianPoolsDTO } from "../../../types/resourcePools";
import useLazyLoadEditTab from "../../../hooks/useLazyLoadEditTab";

export function ClientClinicianPoolsEditTab(props: EditTabProps) {
  const shouldDisplay = useLazyLoadEditTab(props.path);

  return (
    <FormTab {...props} label="CP Management">
      {!shouldDisplay ? null : (
        <>
          <Typography variant="h2">CP Resource Pools</Typography>
          <Typography variant="body1">
            Note: You can link only one resource pool for each specialty
          </Typography>
          <ArrayInput source="clientClinicianPools" level={2}>
            <SimpleFormIterator
              addButton={<FormIteratorAddButton label="Add resource pool" />}
              removeButton={
                <FormIteratorRemoveButton
                  label="Remove resource pool"
                  sx={{ marginBottom: "5px" }}
                />
              }
            >
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource }) => {
                  if (!getSource) return null;

                  const linkedServices = formData.clientClinicianPools
                    .filter(
                      (x: ClientClinicianPoolsDTO["data"]) =>
                        x.attributes?.resourcePoolId &&
                        x.resourcePools?.[0].attributes?.clinicalServiceId
                    )
                    .map((x: ClientClinicianPoolsDTO["data"]) =>
                      x.resourcePools?.[0].attributes?.clinicalServiceId?.toString()
                    );

                  const linkedSpecialties = formData.clientClinicianPools
                    .filter(
                      (x: ClientClinicianPoolsDTO["data"]) =>
                        x.attributes?.resourcePoolId &&
                        x.resourcePools?.[0].attributes?.specialty
                    )
                    .map(
                      (x: ClientClinicianPoolsDTO["data"]) =>
                        x.resourcePools?.[0].attributes?.specialty
                    );

                  const service = scopedFormData?.resourcePools?.[0].service;
                  const isSpecialityFilter = isNaN(service);

                  const resourcePoolFilter = {
                    specialty: service && isSpecialityFilter ? service : null,
                    clinicalServiceId:
                      service && !isSpecialityFilter ? service : null,
                  };

                  return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {scopedFormData.resourcePools?.[0]?.attributes
                        ?.serviceName ? (
                        <TextInput
                          sx={{ width: "240px", marginRight: "20px" }}
                          label={"Service"}
                          source={getSource(
                            "resourcePools.[0].attributes.serviceName"
                          )}
                          disabled
                        />
                      ) : (
                        <ReferenceInput
                          reference="clinicalServicesWithSpecialities"
                          source={getSource("resourcePools.[0].service")}
                          filter={{
                            excludedServices: linkedServices,
                            excludedSpecialties: linkedSpecialties,
                            deliveryType: "Synchronous",
                          }}
                          isRequired
                        >
                          <AutocompleteInput
                            sx={{ width: "240px", marginRight: "20px" }}
                            required
                            label="Service"
                            optionText="name"
                            filterToQuery={query => ({
                              name: query,
                            })}
                            groupBy={choice => choice.group}
                          />
                        </ReferenceInput>
                      )}
                      {scopedFormData.id ? (
                        <TextInput
                          sx={{ width: "240px" }}
                          label={"Resource Pool"}
                          source={getSource(
                            "resourcePools.[0].attributes.name"
                          )}
                          disabled
                        />
                      ) : (
                        <ReferenceInput
                          source={getSource(`attributes.resourcePoolId`)}
                          reference="resourcePools"
                          required
                          filter={resourcePoolFilter}
                        >
                          <AutocompleteInput
                            required
                            source={getSource(`attributes.resourcePoolId`)}
                            sx={{ width: "240px" }}
                            disabled={
                              !scopedFormData.resourcePools?.[0].service
                            }
                            label="Resource Pool"
                            optionText="attributes.name"
                            filterToQuery={query => ({
                              name: query,
                            })}
                          />
                        </ReferenceInput>
                      )}
                      <BooleanInput
                        sx={{ marginLeft: "20px", marginTop: "20px" }}
                        source={getSource("attributes.isExclusive")}
                        label="Is Exclusive"
                      />
                    </Box>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </>
      )}
    </FormTab>
  );
}
