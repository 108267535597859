import React from "react";
import { FieldProps, TextField, useRecordContext } from "react-admin";

export function RoleField(props: FieldProps) {
  const record = useRecordContext();
  const { isCsoUser } = record!.attributes;

  record!.role = isCsoUser ? "CSO" : "Admin";

  return <TextField {...props} record={record} />;
}
